import React from "react";
import axios from "axios";
import { Route, withRouter, Switch } from "react-router-dom";
import { Wizard, Steps, Step } from "react-albus";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Line } from "rc-progress";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import WelcomeForm from "./containers/WelcomeForm";
import LocationForm from "./containers/LocationForm";
import BillingInformationForm from "./containers/BillingInformationForm";
import AccountForm from "./containers/AccountForm";
import SucceedForm from "./containers/SucceedForm";
import SubscriptionManagement from "./containers/SubscriptionManagement";

import { InjectedCheckoutForm } from "./containers/CheckoutForm";
import LanguageSwitch from "./components/LanguageSwitch";

import { LANGUAGE_OPTIONS } from "./general";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: "fi",

      newUser: {
        // Company
        companyName: "",
        companyVatId: "",
        companyAddress: "",
        companyZip: "",
        companyCity: "",

        // Location
        locationName: "",
        locationSpace: "",
        locationAddress: "",
        locationZip: "",
        locationCity: "",

        // Contact person
        contactName: "",
        contactTitle: "",
        contactPhone: "",
        contactEmail: "",

        // Checkboxes
        termsOfService: false,
        marketing: false,

        // Jukebox
        jukebox: 0,

        // Language preference
        language: "",
      },
      accountInfo: {
        username: "",
        password: "",
        passwordConfirm: "",
        customerIdToken: "",
      },
      cardInfo: {
        fullName: "",
        cardNumber: "",
        expDate: "",
        cvc: "",
      },

      jwtToken: "",
      usersIdToken: "",
      customerIdToken: "",

      stripePublishableKey: null,

      productData: {},
      cityList: [],

      streamFactoryUrl: "",

      loadingError: false,
    };

    this.stripePromise = null;
    this.currentPath = "";

    this.idToClassMap = new Proxy({ welcome: "container-big", location: "container-big", card: "container-card" }, {
      get: (target, name) => {
        return target.hasOwnProperty(name) ? target[name] : "container-small"
      }
    });
  }

  addUserData = (userData) => {
    const updatedUserData = Object.assign({}, this.state.newUser, userData, { language: this.state.langKey });

    this.setState({
      newUser: updatedUserData
    });
  };

  getCityId = (city) => {
    const cityObject = this.state.cityList.find(cityObj => {
      if (city.toLowerCase() === cityObj.city.toLowerCase()) {
        return true
      }
      return false
    });
    return cityObject.id;
  }

  createOrCheckUser = async (acco, create) => {
    this.setState({
      accountInfo: {
        username: acco.username,
        password: acco.password,
        passwordConfirm: acco.passwordConfirm,
        customerIdToken: acco.customerIdToken,
      },
    });

    // Convert "locationCity" and "marketing" into API's format.
    const locationCityId = this.getCityId(this.state.newUser.locationCity);
    const marketing = this.state.newUser.marketing ? 1 : 0;

    let mergedUserdata = Object.assign(
      {},
      this.state.newUser,
      acco,
      {
        locationCity: locationCityId,
        marketing: marketing
      }
    );

    const response = await axios
      .post(
        create === "check" ? "/api/checkUsers" : "/api/users",
        mergedUserdata
      )
      .then((res) => {
        // Username does not exist.
        if (res.status === 200) {
          return res.status;
        }
        // User created.
        else if (res.status === 201) {
          // Save received JWT token to localStorage and App's state.
          localStorage.setItem("jwtToken", res.data.jwtToken);
          this.setState({
            jwtToken: res.data.jwtToken,
            usersIdToken: res.data.usersIdToken
          });

          return res.status;
        } else {
          console.log("Something wrong?");
          return res.status;
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response) {
            console.log("err:", err.response);
            if (err.response.status === 409) {
              console.log("Username already exists.");
              return err.response.status;
            } else if (err.response.status === 422) {
              console.log("Information missing?");
              return err.response.status;
            } else {
              console.log("Unknown error.");
            }
            return err.response.status;
          }
        }
      });

    return response;
  };

  //luodaan tunnus vasta muistiin
  checkUser = async (acco) => {
    return await this.createOrCheckUser(acco, "check");
  };

  addAccount = async (acco) => {
    return await this.createOrCheckUser(acco, "create");
  };

  proceed = (next) => {
    next();
  };

  succeed = () => {
    this.props.history.push("/subscriptions");
  };

  loadStripeLibrary = (locale) => {
    const allowedPaths = ["/signup/location", "/signup/billing", "/signup/account"];
    const pathname = this.props.location.pathname;

    if (allowedPaths.indexOf(pathname) === -1) {
      return null;
    }

    if (this.state.stripePublishableKey) {
      this.stripePromise = loadStripe(this.state.stripePublishableKey, { locale: locale });
    }
  }

  urlCheck = () => {
    const query = new URLSearchParams(this.props.location.search);
    let lang = query.get("lang");
    const jwtToken = query.get("token");

    if (jwtToken) {
      localStorage.setItem("jwtToken", jwtToken);
    }

    if (!lang || !LANGUAGE_OPTIONS.includes(lang)) {
      lang = "fi";
    }

    this.changeLanguage(lang);
  };

  updateLanguage = (lang) => {
    // Disable language changing atm.

    // this.props.history.replace({
    //   pathname: this.props.location.pathname,
    //   search: `?lang=${lang}`,
    // });
  };

  changeLanguage = (lang) => {
    // Disable language changing atm.

    // this.setState({ langKey: lang });
    // this.updateLanguage(lang);
    // this.loadStripeLibrary(lang);
  };

  setStripePublishableKey = (stripePublishableKey) => {
    this.setState({ stripePublishableKey });
  }

  setCityList = (cityList) => {
    this.setState({ cityList });
  }

  setProductData = (productData) => {
    this.setState({ productData });
  }

  setUsersIdToken = (usersIdToken) => {
    this.setState({ usersIdToken });
  }

  setCustomerIdToken = (customerIdToken) => {
    this.setState({ customerIdToken });
  }

  componentDidMount() {
    this.urlCheck();

    const { history } = this.props;
    const navigatingBackDisabledPaths = ["/signup/card", "/signup/succeed", "/subscriptions"];

    if (!this.state.streamFactoryUrl) {
      axios.get("/api/configs/urls", { timeout: 7000 })
        .then(res => {
          console.log("streamFactoryUrl received:", res.data.streamFactoryUrl);
          this.setState({ streamFactoryUrl: res.data.streamFactoryUrl });
        })
        .catch(err => {
          console.log("Error fetching streamFactoryUrl.");
          this.setState({ loadingError: true });
        });
    }

    if (localStorage.hasOwnProperty("jwtToken")) {
      this.setState({ jwtToken: localStorage.getItem("jwtToken") });
    }

    // Work in progress. Does not work correctly when user has gone past the back disabled page and moves back multiple times.
    this.props.history.listen((location, action) => {
      if (action === "PUSH") {
        if (this.currentPath !== location.pathname) {
          this.currentPath = location.pathname;
          if (navigatingBackDisabledPaths.includes(this.currentPath)) {
            history.push({
              pathname: location.pathname,
              search: location.search
            });
          }
        }
      }
      if (action === "POP") {
        if (navigatingBackDisabledPaths.includes(this.currentPath)) {
          history.go(1);
        }
      }
    });
  };

  componentDidUpdate() {
    // console.log("App.js updated");
  };

  render() {
    return (
      <div className="pad-t">
        <nav className="navbar sticky-top navbar-light bg-light navbar-expand-lg">
          <div className="row">
            <div className="navbar-brand m-0 m-sm-1">
              <img
                src="/img/streamfactory_temp_logo.png"
                width="200"
                draggable="false"
                alt="DjOnline StreamFactory"
              />
              <div className="navbar-brand m-0 m-sm-1">
                {/* <LanguageSwitch
                  langFunc={this.changeLanguage}
                  langKey={this.state.langKey}
                /> */}
              </div>
            </div>
          </div>
        </nav>

        <Switch>
          <Route path="/subscriptions">
            <SubscriptionManagement
              jwtToken={this.state.jwtToken}
              langKey={this.state.langKey}
              streamFactoryUrl={this.state.streamFactoryUrl}
              updateLanguage={this.updateLanguage}
            />
          </Route>
          <Route
            path={["/", "/signup"]}
            render={({ history }) => (
              <Wizard
                history={history}
                basename="/signup"
                render={({ next, step, steps }) => (
                  <div id="form-container" className={this.idToClassMap[step.id]}>
                    <TransitionGroup>
                      <CSSTransition
                        key={step.id}
                        classNames="frm"
                        timeout={{ enter: 350, exit: 250 }}
                      >
                        <div className="frm-steps fluid rounded">
                          <Line
                            percent={
                              ((steps.indexOf(step) + 1) / steps.length) * 100
                            }
                            className="container"
                          />

                          <Steps
                            key={step.id}
                            step={step}
                            search={this.props.location.search}
                          >
                            <Step id="welcome">
                              <WelcomeForm
                                step={steps.indexOf(step) + 1 + "/" + steps.length}
                                loadingError={this.state.loadingError}
                                langKey={this.state.langKey}
                                updateLanguage={this.updateLanguage}
                              />
                            </Step>
                            <Step id="location">
                              <LocationForm
                                step={steps.indexOf(step) + 1 + "/" + steps.length}
                                addUserData={this.addUserData}
                                newUser={this.state.newUser}
                                cityList={this.state.cityList}
                                setCityList={this.setCityList}
                                productData={this.state.productData}
                                setProductData={this.setProductData}
                                stripePublishableKey={this.state.stripePublishableKey}
                                setStripePublishableKey={this.setStripePublishableKey}
                                loadStripeLibrary={this.loadStripeLibrary}
                                loadingError={this.state.loadingError}
                                langKey={this.state.langKey}
                                updateLanguage={this.updateLanguage}
                              />
                            </Step>
                            <Step id="billing">
                              <BillingInformationForm
                                step={steps.indexOf(step) + 1 + "/" + steps.length}
                                addUserData={this.addUserData}
                                newUser={this.state.newUser}
                                cityList={this.state.cityList}
                                langKey={this.state.langKey}
                                updateLanguage={this.updateLanguage}
                              />
                            </Step>
                            <Step id="account">
                              <AccountForm
                                step={steps.indexOf(step) + 1 + "/" + steps.length}
                                {...this.props} // <- is needed??
                                accountInfo={this.state.accountInfo}
                                checkUser={this.checkUser}
                                langKey={this.state.langKey}
                                updateLanguage={this.updateLanguage}
                              />
                            </Step>
                            <Step id="card">
                              <Elements stripe={this.stripePromise}>
                                <InjectedCheckoutForm
                                  step={steps.indexOf(step) + 1 + "/" + steps.length}
                                  productData={this.state.productData}
                                  addUserData={this.addUserData}
                                  newUser={this.state.newUser}
                                  accountInfo={this.state.accountInfo}
                                  setCustomerIdToken={this.setCustomerIdToken}
                                  proceed={() => this.proceed(next)}
                                  addAccount={this.addAccount}
                                  langKey={this.state.langKey}
                                  updateLanguage={this.updateLanguage}
                                />
                              </Elements>
                            </Step>
                            <Step id="succeed">
                              <SucceedForm
                                step={steps.indexOf(step) + 1 + "/" + steps.length}
                                jwtToken={this.state.jwtToken}
                                usersIdToken={this.state.usersIdToken}
                                customerIdToken={this.state.customerIdToken}
                                succeed={this.succeed}
                                langKey={this.state.langKey}
                                email={this.state.newUser.contactEmail}
                                streamFactoryUrl={this.state.streamFactoryUrl}
                                updateLanguage={this.updateLanguage}
                              />
                            </Step>
                          </Steps>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                )}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
