import React from "react";
/*
  props:
  name: objektin nimi (jolla erotellaan muista)
  value: sisältö jota kirjoitetaan
  inputType: esim text tai password
  onChange: handleri joka käsittelee tekstiä
  placeholder: jos ei mitään, rendataan tämä teksti
*/
const PasswordInput = (props) => {
  return (
    <div className="form-group col col-sm-10">
      <input
        className={props.clsName}
        autoCorrect="off"
        autoCapitalize="off"
        autoComplete="off"
        id={props.name}
        name={props.name}
        type={props.visible ? "text" : props.inputType}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
      />
      <div className="password-toggle" onClick={() => props.handlePasswordToggle(props.name)}>
        {props.visible ? <span className="fa fa-eye-slash"></span> : <span className="fa fa-eye"></span>}
      </div>
      <label htmlFor={props.name} className="floating-label">{props.placeholder}</label>
      {props.err}
    </div>
  );
};

export default PasswordInput;
