// @ts-check

import React from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

/**
 * Modal for confirmations.
 * 
 * @param {object} props Props passed down to component.
 * @param {boolean} props.showState `true` to show modal, `false` to hide.
 * @param {string} [props.title] Title text of the modal.
 * @param {string} props.promptText Text shown to user.
 * @param {string} props.buttonCancelText Cancel button's text.
 * @param {string} props.buttonAgreeText Agree button's text.
 * @param {function} props.handleClose Function to handle closing the modal.
 * @param {function} props.handleChoice Function to handle cancel/agree.
 */
const ConfirmationModal = (props) => {
  return (
    <>
      <Modal show={props.showState} onHide={props.handleClose} centered>
        {props.title ? <Modal.Header>
          <Modal.Title>
            {props.title}
          </Modal.Title>
        </Modal.Header> : false}
        <Modal.Body className="mt-3">
          {props.promptText}
          <div className="row justify-content-center mt-3 mx-2">
            <div className="navigation-wrapper flex-row-reverse">
              <button className="btn btn-custom btn-primary" onClick={() => { props.handleChoice(true) }}>
                {props.buttonAgreeText}
              </button>
              <button className="btn btn-custom btn-secondary" onClick={() => { props.handleChoice(false) }}>
                {props.buttonCancelText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
