import { texts } from "./components/multilanguage";

// export const LANGUAGE_OPTIONS = ["en", "fi"];
export const LANGUAGE_OPTIONS = ["fi"];

export const VALIDATE_FORM = (isError) => {
  let isValid = true;

  Object.keys(isError).forEach((key) => {
    if (isError[key].length > 0) {
      isValid = false;
    }
  });

  return isValid;
};

export const VALIDATE_ZIP = (zip) => {
  if (/^[0-9]{5}$/m.test(zip)) {
    return true;
  }
  return false;
}

export const VALIDATE_USERNAME = (username) => {
  if (/^[a-z][a-z0-9]{3,30}$/m.test(username)) {
    return true;
  }
  return false;
}

export const VALIDATE_EMAIL = (email) => {
  if (/^\S+@\S+\.\S+$/.test(email)) {
    return true;
  }
  return false;
};

export const SIMPLIFY_VAT_ID = (vatId) => {
  return vatId.replace(/(FI|-)/gi, "");
}

export const FINNISH_VAT_FORMAT = (vatId) => {
  const vatIdSimple = SIMPLIFY_VAT_ID(vatId);
  return vatIdSimple.substr(0, vatIdSimple.length - 1) + "-" + vatIdSimple[vatIdSimple.length - 1];
}

export const VALIDATE_VAT_ID = (vatId) => {
  const vatIdSimple = SIMPLIFY_VAT_ID(vatId);

  if (/^\d{8}$/.test(vatIdSimple)) {
    const sepa = vatIdSimple.split("");
    const checkSum = vatIdSimple[vatIdSimple.length - 1];
    const multipliers = [7, 9, 10, 5, 8, 4, 2];
    let sum = 0;
    for (let i = 0; i < 7; i++) sum += parseInt(sepa[i], 10) * multipliers[i];

    let rema = sum % 11;
    if (rema === 0) if (rema === parseInt(checkSum)) return true;

    if (rema >= 2) {
      rema = 11 - rema;
      if (rema === parseInt(checkSum)) return true;
    }
  }
  return false;
};

export const VALIDATE_PHONE = (number) => {
  // Remove some characters and strings that could be used to help present phone numbers.
  const numberSimple = number.replace(
    /(\s+|\\-+|\.+|\/|\(|\)|(extension|ext|x))/gi,
    ""
  );

  // Allow leading '+' and 7-16 numbers in total.
  if (/^[\\+]?\d{7,16}$/im.test(numberSimple)) {
    return true;
  }
  return false;
};

export function MIN_LENGTH_ERROR_MESSAGE(langKey, name) {
  return texts[langKey].errors.value_lt_x_1 +
    INPUT_LENGTHS[name].min +
    texts[langKey].errors.value_lt_x_2;
}

export function MAX_LENGTH_ERROR_MESSAGE(langKey, name) {
  return texts[langKey].errors.value_gt_x_1 +
    INPUT_LENGTHS[name].max +
    texts[langKey].errors.value_gt_x_2;
}

export const REQUIRED_USER_INFO = {
  username: true,
  password: true,
  passwordConfirm: true,
  customerIdToken: true,

  companyName: true,
  companyVatId: true,
  companyAddress: true,
  companyZip: true,
  companyCity: true,

  locationName: true,
  locationSpace: false,
  locationAddress: true,
  locationZip: true,
  locationCity: true,

  contactName: true,
  contactTitle: false,
  contactPhone: true,
  contactEmail: true,

  termsOfService: true,
  marketing: true,
  jukebox: true,
  language: true,
};

export const INPUT_LENGTHS = {
  username: {
    min: 4,
    max: 31
  },
  password: {
    min: 8,
    max: 31
  },
  passwordConfirm: {
    min: 8,
    max: 31
  },

  companyName: {
    min: 3,
    max: 127
  },
  companyVatId: {
    min: 9,
    max: 10
  },
  companyAddress: {
    min: 4,
    max: 254
  },
  companyZip: {
    min: 5,
    max: 5
  },
  companyCity: {
    min: 2,
    max: 127
  },

  locationName: {
    min: 4,
    max: 95
  },
  locationSpace: {
    min: 2,
    max: 31
  },
  locationAddress: {
    min: 4,
    max: 254
  },
  locationZip: {
    min: 5,
    max: 5
  },
  // Not used atm since it is an integer and check is done in a custom way.
  locationCity: {
    min: 2,
    max: 50
  },

  contactName: {
    min: 4,
    max: 127
  },
  // Optional
  contactTitle: {
    min: 2,
    max: 127
  },
  contactPhone: {
    min: 7,
    max: 31
  },
  contactEmail: {
    min: 5,
    max: 127
  },

  musicWishMessage: {
    min: 0,
    max: 500
  }
}
