import React, { Component } from "react";
import ReactCustomFlagSelect from "react-custom-flag-select";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";

const find = (arr, obj) => {
  const res = [];
  arr.forEach((o) => {
    let match = false;
    Object.keys(obj).map((i) => {
      if (obj[i] == o[i]) {
        match = true;
      }
    });
    if (match) {
      res.push(o);
    }
  });
  return res;
};

const FLAG_SELECTOR_OPTION_LIST = [
  {
    unique_id: "fi",
    id: "Suomi",
    name: "FI",
    displayText: "FI",
    locale: "fi-FI",
    flag: require("../img/flags/fi.svg"),
  },
  {
    unique_id: "en",
    id: "English",
    name: "EN",
    displayText: "EN",
    locale: "en-US",
    flag: require("../img/flags/uk.svg"),
  },
];

class LanguageSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,
      areaCode: FLAG_SELECTOR_OPTION_LIST[0].id,
    };
  }

  render() {
    const currentItem = find(FLAG_SELECTOR_OPTION_LIST, {
      unique_id: this.props.langKey,
    })[0];

    return (
      <div>
        <form onSubmit={this.submit}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", height: "45px" }}>
              <ReactCustomFlagSelect
                tabIndex={"1"} //Optional.[String or Number].Default: -1.
                id={"areaCode"} //Optional.[String].Default: "". Input ID.
                name={"areaCode"} //Optional.[String].Default: "". Input name.
                value={currentItem.id} //Optional.[String].Default: "".
                disabled={false} //Optional.[Bool].Default: false.
                showSearch={false} // Optional.[Bool].Default: false. Show a search box in order to find option quickly.
                // keyword={''} // Optional.[String].Default: ''. Show a keyword for search box.
                showArrow={true} //Optional.[Bool].Default: true.
                animate={true} //Optional.[Bool].Default: false.
                optionList={FLAG_SELECTOR_OPTION_LIST} //Required.[Array of Object(s)].Default: [].
                // selectOptionListItemHtml={<div>us</div>} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
                // selectHtml={<div>us</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
                classNameWrapper="" //Optional.[String].Default: "".
                classNameContainer="" //Optional.[String].Default: "".
                classNameOptionListContainer="" //Optional.[String].Default: "".
                classNameOptionListItem="" //Optional.[String].Default: "".
                classNameDropdownIconOptionListItem={""} //Optional.[String].Default: "".
                customStyleWrapper={{}} //Optional.[Object].Default: {}.
                customStyleContainer={{
                  border: "1px solid #ced4da",
                  fontSize: "12px",
                  borderRadius: "4px",
                }} //Optional.[Object].Default: {}.
                customStyleSelect={{ width: "120px" }} //Optional.[Object].Default: {}.
                customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
                customStyleOptionListContainer={{
                  maxHeight: "100px",
                  overflow: "auto",
                  width: "120px",
                }} //Optional.[Object].Default: {}.
                onChange={(areaCode) => {
                  this.setState({ areaCode: areaCode }, () => {
                    const tempItem = find(FLAG_SELECTOR_OPTION_LIST, {
                      id: areaCode,
                    })[0];
                    this.props.langFunc(tempItem.unique_id);
                  });
                }}
                // onBlur={() => {}} //Optional.[Func].Default: none.
                // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default LanguageSwitch;
