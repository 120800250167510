import React from "react";

/*
  props:
  title: sisältöteksti
*/
const Label = (props) => {
  return (
    <div className="form-controller m-2">
      <span className="label label-primary" htmlFor={props.name}>
        {props.title}
      </span>
    </div>
  );
};

export default Label;
