export const texts =
{
  "fi": {
    language: "fi",
    locale: "fi-FI",
    monthText: "kk",
    monthAbbr: "kk",
    decimalPoint: ",",

    tooltipTexts: {
      // Account
      username: "Syötä haluamasi käyttäjätunnus. Käyttäjätunnuksen on oltava vähintään neljä merkkiä pitkä.",
      password: "Syötä salasana jota haluat käyttää järjestelmässä. Salasanan on oltava vähintään 8 merkkiä pitkä.",
      passwordConfirm: "Syötä salasana uudelleen varmistuksen vuoksi.",
      email: "Sähköpostiosoite johon lasku lähetetään",
      email_checkout: "Sähköpostiosoite johon lasku lähetetään.",

      // Company
      companyName: "Yrityksen nimi.",
      companyVatId: "Yrityksen Y-tunnus.",
      companyAddress: "Yrityksen osoite.",

      // Location
      locationName: "Toimipisteen nimi, eli se mihin palvelu otetaan käyttöön. Nimen on oltava vähintään neljän merkin pituinen.",
      locationSpace: "Tilan nimi. Toimipisteessä voi olla useita tiloja ja erottelulla varmistetaan että palvelu tulee oikeaan paikkaan.",
      locationAddress: "Osoite johon palvelu tulee käyttöön.",

      // Contact person
      contactName: "Henkilö, johon olemme yhteydessä.",
      contactTitle: "Henkilön titteli työpaikalla, esimerkiksi CEO, ohjelmavastaava jne.",
      contactPhone: "Puhelinnumero johon voimme tarvittaessa soittaa.",
      contactEmail: "Sähköpostiosoite johon voimme tarvittaessa olla yhteydessä.",
    },
    //kenttien sisällöt
    placeholders: {
      username: "Käyttäjätunnus",
      password: "Salasana",
      passwordConfirm: "Salasana uudestaan",

      companyName: "Nimi",
      companyVatId: "Y-tunnus",
      companyAddress: "Osoite",
      companyZip: "Postinumero",
      companyCity: "Paikkakunta",

      locationName: "Nimi",
      locationSpace: "Tilan nimi",
      locationAddress: "Osoite",
      locationZip: "Postinumero",
      locationCity: "Paikkakunta",

      contactName: "Nimi",
      contactTitle: "Titteli",
      contactPhone: "Puhelinnumero",
      contactEmail: "Sähköpostiosoite",

      optional: "valinnainen",
    },
    titles: {
      //js-tiedoston nimen mukaan
      pageTitles: {
        signUpForm: "Käyttäjätiedot",
        welcomeForm: "Tervetuloa käyttämään StreamFactorya",
        locationForm: "Käyttöpaikka",
        billingInformationForm: "Laskutustiedot",
        accountForm: "Käyttäjätunnus",
        checkoutForm: "Maksu",
        succeedForm: "Kiitos tilauksestasi!",
        subscriptions: "Tilaukset",
      },

      //sivulla esiintyvät tekstit.
      labels: {
        location: "Toimipiste",
        company: "Yritys",
        contactPerson: "Yhteyshenkilö",
        termsOfService_1: "Hyväksyn ",
        termsOfService_2: "toimitusehdot",
        TOSandPrivacy_1: "Olen lukenut ja hyväksyn ",
        TOSandPrivacy_2: "toimitusehdot",
        TOSandPrivacy_3: " ja ",
        TOSandPrivacy_4: "tietosuojaselosteen",
        marketing: "Markkinointilupa",

        succeed_label_pt1: "Tilausvahvistus ja tietoja tilauksen hallinnasta on lähetetty sähköpostiosoitteeseen: ",

        trial: "Tilaus sisältää 7 päivän maksuttoman testijakson. Voit halutessasi lopettaa tilauksen testijaksoon ilman, että sinulle muodostuu mitään kuluja.",
        checkout_label: "Oletuksena laskutukseen liittyvät kuitit lähetetään alla näkyvään yhteyshenkilön sähköpostiosoitteeseen. Halutessasi voit muuttaa tätä sähköpostiosoitetta, johon kuitit tullaan lähettämään.",

        musicForm: "Kuvailkaa lopuksi vielä muutamalla lauseella millaista musiikkia asiakastilaanne toivotte. Mitkä asiat ovat in, ja mitkä out. Tämän avulla asiantuntijamme luovat teille oman musiikkityylin. Tarvittaessa he myös ottavat teihin yhteyttä.",
      },
    },

    buttons: {
      close: "Sulje",
      cancel: "Peru",
      back: "Takaisin",
      agree: "Kyllä",
      refresh: "Päivitä",
    },

    //vahvistus-modaali
    confirmationModal: {
      title: "",
      promptText: "Lomakkeessa on tekstiä. Haluatko varmasti jatkaa lähettämättä?",
      buttonCancel: "Takaisin",
      buttonAgree: "Kyllä",
    },

    //kaikki napit
    navigation: {
      next: "Seuraava",
      previous: "Edellinen",
      login: "Kirjaudu sisään",
      order: "Tilaa",
      skip: "Ohita",
      send: "Lähetä",

      proceedToSubscriptionManagement: "Tarkastele tilaustasi",
      proceedToStreamFactory: "Siirry StreamFactoryyn",
    },

    welcomeFormTexts: {
      lead: "Me DjOnlinella tiedämme vuosien kokemuksesta, miten suurta roolia taustamusiikki näyttelee osana palvelukokemusta. Siksi kehitimme StreamFactoryn – selainpohjaisen musiikin suoratoistopalvelun, jonka dynaamiset soittolistat varmistavat oikeanlaisen musiikkitunnelman liiketiloissasi. Voit hallita soittolistoja itse mistä ja milloin tahansa tai jättää musiikin täysin StreamFactoryn huoleksi.",
      body: "Tilauksen aloittamalla pääset heti kokeilemaan käyttöliittymää sekä lukuisia valmiita soittolistoja. Muutaman päivän kuluessa kokeneet äänisuunnittelijamme kartoittavat tarpeesi tarkemmin, jonka perusteella rakennamme personoidun ja uniikin musiikkisisällön luomaan ainutlaatuisen tunnelman juuri teille.",
      trial: "Kokeile nyt StreamFactorya veloituksetta viikon testijakson ajan. Voit halutessasi lopettaa tilauksen ilman kuluja koska tahansa testijakson aikana. Testijakson jälkeen palvelun hinta on 59€ kuukaudessa.",
    },

    //tilauksien hallintasivun tekstejä
    subscription: {
      trial: "Testijakso",
      active: "Aktiivinen",
      loadingSubscriptionData: "Ladataan tilaustietoja",
      noActiveSubscriptions: "Ei aktiivisia tilauksia",
      subscribe: "Tilaa",
      cancel: "Peru tilaus",
      renew: "Uusi",

      table: {
        product: "Tuote",
        price: "Hinta",
        status: "Tila",
        trialEnd: "Testijakson päättyminen",
        subscriptionStartDate: "Tilauksen alku",
        subscriptionEndDate: "Tilauksen päättyminen",
        subscriptionWillEndAfterTrial: "Tilaus päättyy testijakson jälkeen",

        preAfter: "",
        afterMinutes: "minuutin kuluttua",
        afterHours: "tunnin kuluttua",
        afterDays: "päivän kuluttua",
        subscriptionHasEnded: "Tilaus on päättynyt",
      },

      includingVAT: " €/kk sis. ALV 24 %",

      customerPortalTitle: "Asiakasportaali",
      customerPortalDescription: "Asiakasportaalin kautta voit päivittää maksukorttitietoja sekä tarkastella laskutushistoriaa.",
      customerPortalButton: "Avaa asiakasportaali",

      authenticationFailed: "Tunnistautuminen epäonnistui.",
      authenticationFailInfo: "Päästäksesi näkemään ja muokkaamaan tilaustasi, ole hyvä ja käytä yhteyshenkilön sähköpostiosoitteeseen lähetettyä linkkiä. Linkki on lähetetty yhteyshenkilölle tilin luomisen yhteydessä.",
      connectionTimeout: "Ongelmia verkkoyhteydessä. Ole hyvä ja yritä uudestaan hetken kuluttua.",
      subscriptionUndergoingChanges: "Tilaus on automaattisten muutosten alaisena. Osa näkyvistä tiedoista voi olla vanhentunutta. Ole hyvä ja yritä uudestaan muutaman minuutin kuluttua.",
      refresh: "Päivitä",
    },

    //virheet ja varoitukset
    errors: {
      accountAlreadyExists: "Tunnus on jo olemassa.",
      missingInformation: "Jotain tietoja puuttuu.",
      creatingStreamFactoryUser: "Tunnuksen luominen epäonnistui. Ole hyvä ja yritä uudelleen. Jos ongelma toistuu, ota yhteyttä asiakastukeen.",
      unknown: "Tuntematon virhe.",

      usernameInvalidFormat: "Virheellinen käyttäjänimi. Käyttäjänimen on oltava 4-31 merkkiä pitkä ja alettava kirjaimella. Käyttäjänimi saa sisältää vain pieniä kirjaimia väliltä a-z, sekä numeroita.",
      passwordCompareMismatch: "Salasanat eivät täsmää",

      value_lt_x_1: "Vaatii vähintään ",
      value_lt_x_2: " merkkiä.",
      value_gt_x_1: "Syöte saa olla enintään ",
      value_gt_x_2: " merkkiä pitkä.",

      value_lt_2: "Vaatii vähintään kaksi merkkiä.",
      value_lt_3: "Vaatii vähintään kolme merkkiä.",
      value_lt_4: "Vaatii vähintään neljä merkkiä.",
      value_lt_8: "Vaatii vähintään kahdeksan merkkiä.",

      emailInvalid: "Sähköpostin muoto on virheellinen. Ole hyvä ja tarkista sen olevan muodossa esi@merkki.com.",
      vatIdInvalid: "Y-tunnuksen muoto on virheellinen.",
      cityInvalid: "Virheellinen kaupunki.",
      zipInvalid: "Virheellinen postinumero.",
      phoneNumberInvalid: "Puhelinnumero on virheellisessä muodossa.",
      termsOfService: "Toimitusehdot ja tietosuojaseloste on hyväksyttävä ennen jatkamista.",

      generatingForm: "Virhe lomakkeen luonnissa. Ole hyvä ja yritä uudestaan päivittämällä sivu.",

      stripe_subscription: "Ongelmia tilauksen luomisessa. Ole hyvä ja yritä uudelleen. Jos ongelma toistuu, ota yhteyttä asiakastukeen.",

      card_err_1: "Maksukortin liittäminen asiakastunnukseen epäonnistui. Ole hyvä yritä uudestaan, tai ota yhteyttä asiakastukeen.",
      card_err_2: "Korttitiedon yhdistämisessä tai yhteyden muodostamisessa ilmeni virhe. Yritä myöhemmin uudelleen tai ota yhteyttä asiakastukeen.",
      card_err_3: "Kortin varmentaminen epäonnistui. Ole hyvä ja yritä uudestaan.",

      err_notrequest: "Taustapalvelin ei vastaan. Yritä myöhemmin uudelleen tai ota yhteyttä asiakastukeen.",
      err_generic: "Yritä myöhemmin uudelleen tai ota yhteyttä asiakastukeen.",
      err_card_declined: "Korttisi on hylätty.",

      network: "Ongelmia verkkoyhteydessä. Ole hyvä yritä uudestaan.",
    },
  },

  //englanti
  "en": {
    language: "en",
    locale: "en-GB",
    monthText: "month",
    monthAbbr: "mo",
    decimalPoint: ".",

    tooltipTexts: {
      // Account
      username: "Enter your username. Username must be at leat 4 characters long.",
      password: "Enter your password. Password must be at least 8 characters long.",
      passwordConfirm: "Re-enter the password for confirmation",
      email: "The email address to which the receipts will be send.",
      email_checkout: "The email address to which the receipts will be send.",

      // Company
      companyName: "Company name.",
      companyVatId: "Business ID.",
      companyAddress: "Company address.",

      // Location
      locationName: "The name of the location, ie where the service is used. The name must be at least 4 characters long.",
      locationSpace: "Space name. The location can have several spaces and this field ensures that the service comes to the right space.",
      locationAddress: "The address in which the service will be used.",

      // Contact person
      contactName: "Contact person's name.",
      contactTitle: "Contact person's title, for example CEO, program manager, etc.",
      contactPhone: "Contact person's phone number.",
      contactEmail: "Contact person's email address.",
    },
    //kenttien sisällöt
    placeholders: {
      username: "Username",
      password: "Password",
      passwordConfirm: "Confirm password",

      companyName: "Company name",
      companyVatId: "Business ID",
      companyAddress: "Address",
      companyZip: "Postal code",
      companyCity: "City",

      locationName: "Location name",
      locationSpace: "Space name",
      locationAddress: "Address",
      locationZip: "Postal code",
      locationCity: "City",

      contactName: "Name",
      contactTitle: "Title",
      contactPhone: "Phone",
      contactEmail: "Email",

      optional: "optional",
    },
    titles: {
      //js-tiedoston nimen mukaan
      pageTitles: {
        signUpForm: "User information",
        welcomeForm: "Welcome",
        locationForm: "Place of business",
        billingInformationForm: "Billing information",
        accountForm: "Account",
        checkoutForm: "Checkout",
        succeedForm: "Thank you for your order!",
        subscriptions: "Subscriptions",
      },

      //sivulla esiintyvät tekstit.
      labels: {
        location: "Location",
        company: "Company",
        contactPerson: "Contact person",
        termsOfService_1: "I agree to the ",
        termsOfService_2: "Terms of Delivery",
        TOSandPrivacy_1: "I have read and agree to the ",
        TOSandPrivacy_2: "Terms of Delivery",
        TOSandPrivacy_3: " and ",
        TOSandPrivacy_4: "Privacy Policy",
        marketing: "Marketing authorization",

        succeed_label_pt1: "Confirmation of your order and information about managing your subscription has been sent to the email: ",

        trial: "The subscription includes a free 7 day trial. If you unsubscribe during the trial, you can still use the service for free until the trial ends.",
        checkout_label: "By default, the receipts are sent to contact person's email address shown below. By entering another email address, you can change the destination to which receipts are sent.",

        musicForm: "Finally, please describe in a few words what type of music you wish to be played at your location. What is \"in\" and what is \"out\". With this information our experts will create a custom music style tailored to your needs. They may also contact you personally when needed.",
      },
    },

    buttons: {
      close: "Close",
      cancel: "Cancel",
      back: "Back",
      agree: "Agree",
      refresh: "Refresh",
    },

    //vahvistus-modaali
    confirmationModal: {
      title: "",
      promptText: "The form contains text. Continue without sending?",
      buttonCancel: "Back",
      buttonAgree: "Yes",
    },

    //kaikki napit
    navigation: {
      next: "Next",
      previous: "Back",
      login: "Login",
      order: "Subscribe",
      skip: "Skip",
      send: "Send",

      proceedToSubscriptionManagement: "View your subscription",
      proceedToStreamFactory: "Proceed to StreamFactory",
    },

    welcomeFormTexts: {
      lead: "DJ Online's new streaming service is finally available for a price 59 €/month!",
      body: "In StreamFactory you can add and remove songs and control the schedule through a web based user interface just like in original DJ Online...",
      trial: "Order now to get a 7 day tri...",
    },

    //tilauksien hallintasivun tekstejä
    subscription: {
      trial: "On Trial",
      active: "Active",
      loadingSubscriptionData: "Loading Subscription Data",
      noActiveSubscriptions: "No active subscriptions",
      subscribe: "Subscribe",
      cancel: "Cancel subscription",
      renew: "Renew",

      table: {
        product: "Product",
        price: "Price",
        status: "Status",
        trialEnd: "Trial End",
        subscriptionStartDate: "Start Date",
        subscriptionEndDate: "End Date",
        subscriptionWillEndAfterTrial: "Subscription will end after trial",

        preAfter: "in ",
        afterMinutes: "minutes",
        afterHours: "hours",
        afterDays: "days",
        subscriptionHasEnded: "Subscription has ended",
      },

      includingVAT: "€/mo incl. VAT 24%",

      customerPortalTitle: "Customer portal",
      customerPortalDescription: "Customer portal allows you to update your payment card information and view your billing history.",
      customerPortalButton: "Open customer portal",

      authenticationFailed: "Authentication failed.",
      authenticationFailInfo: "In order to view and manage your subscription, please use the link sent to the contact person's email address during the account creation.",
      connectionTimeout: "Could not connect to the server. Please try again in a moment.",
      subscriptionUndergoingChanges: "Subscription is undergoing automatic changes. Some information presented might be outdated. Please wait for a couple of minutes before trying again.",
      refresh: "Refresh",
    },

    //virheet ja varoitukset
    errors: {
      accountAlreadyExists: "Account already exists.",
      missingInformation: "Missing some information.",
      creatingStreamFactoryUser: "Failed to create an account. Please try again. If the problem persists, contact our customer support.",
      unknown: "Unknown error.",

      usernameInvalidFormat: "Invalid username. Username has to be 4-31 characters long and it can not start with a digit. Username can only contain lowercase characters between a-z and digits.",
      passwordCompareMismatch: "Passwords do not match.",

      value_lt_x_1: "Requires at least ",
      value_lt_x_2: " characters.",
      value_gt_x_1: "Input can be at most ",
      value_gt_x_2: " characters long.",

      value_lt_2: "Requires at least 2 characters.",
      value_lt_3: "Requires at least 3 characters.",
      value_lt_4: "Requires at least 4 characters.",
      value_lt_8: "Requires at least 8 characters.",

      emailInvalid: "Email format is invalid. Please fix it to continue.",
      vatIdInvalid: "The business ID format is invalid.",
      cityInvalid: "Invalid city name.",
      zipInvalid: "Invalid postal code.",
      phoneNumberInvalid: "Phone number is not in the correct format.",
      termsOfService: "Terms of Delivery and Privacy Policy must be accepted.",

      generatingForm: "Error generating form. Please try again by refreshing the page.",

      stripe_subscription: "Problems creating the subscription. Please try again. If the problem persists, contact our customer support.",

      card_err_1: "Connecting the payment card to customer ID was not successful. Please try again or contact our customer support.",
      card_err_2: "There was an error merging or connecting the card information. Try again later or contact our customer support.",
      card_err_3: "Authentication failed. Please try again.",

      err_notrequest: "Backend server doesn't response. Please try again later or contact our customer support.",
      err_generic: "Try again later or contact our customer support.",
      err_card_declined: "Card is declined.",

      network: "Problems with internet connection. Please try again.",
    },
  },

  //tyhjä template
  "": {
    language: "",
    locale: "",
    monthText: "",
    monthAbbr: "",
    decimalPoint: "",

    tooltipTexts: {
      // Account
      username: "",
      password: "",
      passwordConfirm: "",
      email: "",
      email_checkout: "",

      // Company
      companyName: "",
      companyVatId: "",
      companyAddress: "",

      // Location
      locationName: "",
      locationSpace: "",
      locationAddress: "",

      // Contact person
      contactName: "",
      contactTitle: "",
      contactPhone: "",
      contactEmail: "",
    },
    //kenttien sisällöt
    placeholders: {
      username: "",
      password: "",
      passwordConfirm: "",

      companyName: "",
      companyVatId: "",
      companyAddress: "",
      companyZip: "",
      companyCity: "",

      locationName: "",
      locationSpace: "",
      locationAddress: "",
      locationZip: "",
      locationCity: "",

      contactName: "",
      contactTitle: "",
      contactPhone: "",
      contactEmail: "",

      optional: "",
    },
    titles: {
      //js-tiedoston nimen mukaan
      pageTitles: {
        signUpForm: "",
        welcomeForm: "",
        locationForm: "",
        billingInformationForm: "",
        accountForm: "",
        checkoutForm: "",
        succeedForm: "",
        subscriptions: "",
      },

      //sivulla esiintyvät tekstit.
      labels: {
        location: "",
        company: "",
        contactPerson: "",
        termsOfService_1: "",
        termsOfService_2: "",
        TOSandPrivacy_1: "",
        TOSandPrivacy_2: "",
        TOSandPrivacy_3: "",
        TOSandPrivacy_4: "",
        marketing: "",

        succeed_label_pt1: "",

        trial: "",
        checkout_label: "",

        musicForm: "",
      },
    },

    buttons: {
      close: "",
      cancel: "",
      back: "",
      agree: "",
      refresh: "",
    },

    //vahvistus-modaali
    confirmationModal: {
      title: "",
      promptText: "",
      buttonCancel: "",
      buttonAgree: "",
    },

    //kaikki napit
    navigation: {
      next: "",
      previous: "",
      login: "",
      order: "",
      skip: "",
      send: "",

      proceedToSubscriptionManagement: "",
      proceedToStreamFactory: "",
    },

    welcomeFormTexts: {
      lead: "",
      body: "",
      trial: "",
    },

    //tilauksien hallintasivun tekstejä
    subscription: {
      trial: "",
      active: "",
      loadingSubscriptionData: "",
      noActiveSubscriptions: "",
      subscribe: "",
      cancel: "",
      renew: "",

      table: {
        product: "",
        price: "",
        status: "",
        trialEnd: "",
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        subscriptionWillEndAfterTrial: "",

        preAfter: "",
        afterMinutes: "",
        afterHours: "",
        afterDays: "",
        subscriptionHasEnded: "",
      },

      includingVAT: "",

      customerPortalTitle: "",
      customerPortalDescription: "",
      customerPortalButton: "",

      authenticationFailed: "",
      authenticationFailInfo: "",
      connectionTimeout: "",
      subscriptionUndergoingChanges: "",
      refresh: "",
    },

    //virheet ja varoitukset
    errors: {
      accountAlreadyExists: "",
      missingInformation: "",
      creatingStreamFactoryUser: "",
      unknown: "",

      usernameInvalidFormat: "",
      passwordCompareMismatch: "",

      value_lt_x_1: "",
      value_lt_x_2: "",
      value_gt_x_1: "",
      value_gt_x_2: "",

      value_lt_2: "",
      value_lt_3: "",
      value_lt_4: "",
      value_lt_8: "",

      emailInvalid: "",
      vatIdInvalid: "",
      cityInvalid: "",
      zipInvalid: "",
      phoneNumberInvalid: "",
      termsOfService: "",

      generatingForm: "",

      stripe_subscription: "",

      card_err_1: "",
      card_err_2: "",
      card_err_3: "",

      err_notrequest: "",
      err_generic: "",
      err_card_declined: "",

      network: "",
    },
  },
};

export default {
  texts,
};
