import React from "react";

const FI = {
  title:
    <>
      Toimitusehdot 9.7.2021
    </>,
  body:
    <>
      <h4>Toimitettava palvelu</h4>
      <p>Toimittaja sitoutuu toimittamaan asiakkaan käytettäväksi nettioston yhteydessä tilatut streamattavat palvelut. Palvelu toimii kokonaisuudessaan selaimessa ja asiakas saa rekisteröitymisvaiheessa palveluun kirjautumisessa tarvittavan käyttäjätunnuksen ja salasanan. Palvelua voidaan hallinnoida selaimessa toimivan etäkäyttöliittymän välityksellä, johon pääsy tapahtuu samoilla tunnuksilla. Toimittaja ei vastaa palvelun toteuttamiseen tarvittavasta laitteistosta eikä internet yhteydestä.</p>

      <h4>Toimittajan palvelut ja niiden vasteajat</h4>
      <p>Palvelu on asiakkaan käytettävissä välittömästi kun tilaus on suoritettu ja valmiit dynaamiset soittolistat ovat heti käytettävissä. Toimittaja sitoutuu pitämään DJ Onlinen toimintaan liittyvät palvelimet asiakkaan saavutettavissa. Lisäksi toimittaja sitoutuu yhden dynaamisen soittolistan räätälöimiseen kuukauden vasteajalla veloituksetta asiakkaan tarpeiden mukaisesti olemassa olevan musiikkikirjaston sisällön puitteissa. Tämän jälkeen soittolistat ja muutokset soittotyyleissä tehdään hintaan 48e/tunti.</p>

      <h4>Toimittajan oikeudet ja vastuut</h4>
      <p>Toimittaja ei ole vastuussa asiakkaan omistamasta palveluun käytettävästä päätelaitteesta. Toimittajan vastuulla eivät ole minkäänlaiset Internet-yhteyteen liittyvät ongelmat. Toimittaja vastaa vain toimitussopimuksen mukaisesti toimitetusta palvelusta. Toimittaja ei vastaa mistään kolmannen osapuolen palvelusta tai tuotteesta, eikä ole korvausvelvollinen asiakkaalle näiden aiheuttaman teknisen ongelman sattuessa. Toimittajan vastuulla on hoitaa palvelun osalta Teosto- ja Gramex- korvaukset.</p>

      <h4>Asiakkaan oikeudet ja vastuut</h4>
      <p>Maksukorteilla tehtävissä tilauksissa maksutapahtumien käsittelyssä ja hallinnoinnissa on käytössä Stripe Payments Europe Ltd. Asiakkaan on annettava suostumuksensa maksutietojen keräämiseen ja tallentamiseen sekä toistuvien maksujen automaattiseen lunastamiseen tilauksen yhteydessä ilmoitetulta maksutililtä. Stripe Payments Europe Limited, Irlanti, käsittelee Asiakkaan luottokorttitietoja DjOnline-sovelluksessa tehtyjen maksutapahtumien hallinnointia varten. Tarkempia tietoja Stripen käsittelemien tietojen tietosuojasta on Stripen tietosuojakäytännössä (Stripe Privacy Policy: <a href="https://stripe.com/fi/privacy" target="_blank" rel="noopener noreferrer">stripe.com/fi/privacy</a>).</p>
      <p>Asiakas hyväksyy yhteystietojensa käyttämisen asiakaspalveluun ja kampanjointiin liittyvän tiedottamisen puitteissa.</p>
      <p>Asiakkaalla on käyttöoikeus oman päätelaitteen kautta toimittajan tarjoamaan musiikkikirjastoon sekä oikeus toistaa tätä musiikkia ainoastaan tilauksen yhteydessä määritellyssä toimipaikassa.</p>
      <p>Asiakkaan vastuulla on huolehtia Internet-yhteyden kunnossa pitämisestä. Asiakas saa kytkeä päätelaitteen vain äänentoistojärjestelmään, jossa ei ole välitettävän äänisignaalin tallennusmahdollisuutta. Yritys kopioida toistettavia kappaleita katsotaan ja musiikin edelleen välittäminen katsotaan sopimusrikkomukseksi asiakkaan puolelta. Asiakas on velvollinen korvaamaan toimittajan menetykset ja toimittajalle aiheutuneet vahingot sopimusrikkomustilanteessa.</p>

      <h4>Sopimuksen kesto ja irtisanominen</h4>
      <p>Asiakas hyväksyy sopimuksen ehdot tekemällä tilauksen. Tilauksen sisältäessä ilmaisen seitsemän päivän tutustumisjakson, tilauksen voi peruuttaa seitsemän päivän kuluessa tilauksesta ilman kustannuksia. Kokeilujakson jälkeen, kun ensimmäinen korttimaksu on suoritettu kuukausimaksu veloitetaan tililtä automaatisesti siihen hintaan mikä asiakkaalle on tilauksen yhteydessä ilmoitettu. Asiakas voi peruuttaa tilauksen halutessaan koska tahansa yhden kuukauden irtisanomisajalla ja mikäli maksua ei voida veloittaa tililtä, palvelu voidaan katkaista viikon kuluessa maksutapahtuman epäonnistumisesta ellei asiakas ole siihen mennessä korjannut maksutilannetta.</p>

      <h4>Sovellettava laki, erimielisyyksien sovittelu</h4>
      <p>Näihin sopimusehtoihin ja niihin liittyviin sopimussuhteisiin sovelletaan Suomen lakia. Näistä sopimusehdoista tai niihin liittyvistä sopimussuhteista kehkeytyvät erimielisyydet pyritään ratkaisemaan sovinnollisesti. Jos sovintoon ei päästä, erimielisyydet ratkotaan Tampereen käräjäoikeudessa.</p>
    </>,
  footer:
    <>
      <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
      <div className="text-center col-12 mb-2">Copyright Kutalab Oy, kaikki oikeudet pidätetään. Muutokset mahdollisia.</div>
    </>
}


const EN = {
  title:
    <>
      Terms of Delivery 9.7.2021
    </>,
  body:
    <>
      <h4>Delivered services</h4>
      <p>...</p>

      <h4>Vendor's services and response times</h4>
      <p>...</p>

      <h4>Vendor's rights and responsibilites</h4>
      <p>...</p>
      <p>...</p>

      <h4>Customer's rights and responsibilites</h4>
      <p>...</p>
      <p>...</p>

      <h4>Length of contract and term of notice</h4>
      <p>...</p>

      <h4>Applied law, mediation of disputes</h4>
      <p>...</p>
    </>,
  footer:
    <>
      <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
      <div className="text-center col-12 mb-2">Copyright Kutalab Oy, all rights reserved. Changes are possible.</div>
    </>
}

// const EXAMPLE = {
//   title:
//     <>
//       Terms of Delivery 9.7.2021
//     </>,
//   body:
//     <>
//       <h4>Delivered services</h4>
//       <p>...</p>

//       <h4>Vendor's services and response times</h4>
//       <p>...</p>

//       <h4>Vendor's rights and responsibilites</h4>
//       <p>...</p>
//       <p>...</p>

//       <h4>Customer's rights and responsibilites</h4>
//       <p>...</p>
//       <p>...</p>

//       <h4>Length of contract and term of notice</h4>
//       <p>...</p>

//       <h4>Applied law, mediation of disputes</h4>
//       <p>...</p>
//     </>,
//   footer:
//     <>
//       <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
//       <div className="text-center col-12 mb-2">Copyright Kutalab Oy, all rights reserved. Changes are possible.</div>
//     </>
// }

const texts = { fi: FI, en: EN };

export default texts;
