// @ts-check

import React, { Component } from "react";
import axios from "axios";
import SubscriptionItems from "../components/SubscriptionItems";
import CustomerPortalButton from "../components/CustomerPortalButton";
import { texts } from "../components/multilanguage";

class SubscriptionManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptions: [],

      isTokenValid: false,

      subscriptionUndergoingChanges: false,
      loadingSubscriptionData: false,
      connectionTimeout: false
    };
  }

  checkJwtTokenAndFetchSubscriptionData = () => {
    if (this.props.jwtToken) {
      this.setState({
        isTokenValid: true,
        loadingSubscriptionData: true
      }, () => {
        this.getSubscriptionData();
      });
    }
    else {
      this.setState({
        isTokenValid: false
      });
    }
  }

  getSubscriptionData = () => {
    const headers = { "Authorization": `Bearer ${this.props.jwtToken}` };
    axios
      .get("/api/customers/subscriptions", { headers, timeout: 8000 })
      .then((res) => {
        console.log("res.data.subscriptions:", res.data.subscriptionData);
        this.setState({
          isTokenValid: true,
          subscriptions: res.data.subscriptionData.subscriptions,
          subscriptionUndergoingChanges: res.data.subscriptionData.subscriptionUndergoingChanges,
          loadingSubscriptionData: false
        });
      })
      .catch((err) => {
        this.setState({
          loadingSubscriptionData: false
        });
        if (err.response) {
          this.setState({
            isTokenValid: false
          });
        }
        else if (err.request) {
          console.log("Connection timeout when getting subscription data");
          this.setState({
            connectionTimeout: true
          });
        }
        else {
          console.log("Unknown error when getting subscription data");
          console.log(err);
        }
      });
  }

  handleCancel = (e, priceId) => {
    e.preventDefault();
    this.setState({
      loadingSubscriptionData: true
    });
    const headers = { "Authorization": `Bearer ${this.props.jwtToken}` };
    axios
      .post("/api/subscriptions/cancel", { priceId }, { headers, timeout: 10000 })
      .then((res) => {
        this.setState({
          isTokenValid: true,
          subscriptions: res.data.subscriptionData.subscriptions,
          subscriptionUndergoingChanges: res.data.subscriptionData.subscriptionUndergoingChanges,
          loadingSubscriptionData: false
        });
      })
      .catch((err) => {
        console.log("err.response:", err.response);
        this.setState({
          loadingSubscriptionData: false
        });
        if (err.response) {
          this.setState({
            isTokenValid: false
          });
        }
        else if (err.request) {
          console.log("Connection timeout when canceling");
          this.setState({
            connectionTimeout: true
          });
        }
        else {
          console.log("Unknown error when canceling");
          console.log(err);
        }
      });
  }

  handleSubscriptionDataRefetch = () => {
    this.setState({
      loadingSubscriptionData: true,
      connectionTimeout: false
    }, () => {
      this.getSubscriptionData()
    });
  }

  // Needed to fetch subscription data after page refresh, since jwtToken is read from localStorage
  // when App.js mounts, which happens after App's children have mounted.
  componentDidUpdate(prevProps) {
    if (prevProps.jwtToken !== this.props.jwtToken) {
      this.checkJwtTokenAndFetchSubscriptionData();
    }
  }

  componentDidMount() {
    this.checkJwtTokenAndFetchSubscriptionData();
    this.props.updateLanguage(this.props.langKey);
  }

  render() {
    return (
      <div className="container-subscription">

        <div className="subscriptions">
          <h2 className="text-left border-bottom">{texts[this.props.langKey].titles.pageTitles.subscriptions}
            <div className={this.state.loadingSubscriptionData ? "spinner-border subscription-spinner" : "d-none"} role="status"></div>
          </h2>
          {this.state.isTokenValid
            ? <div>
              <div className="text-right">
                <a type="button" className="btn btn-primary btn-sm" href={this.props.streamFactoryUrl}>{texts[this.props.langKey].navigation.proceedToStreamFactory}</a>
              </div>
              <SubscriptionItems
                langKey={this.props.langKey}
                subscriptionUndergoingChanges={this.state.subscriptionUndergoingChanges}
                loadingSubscriptionData={this.state.loadingSubscriptionData}
                connectionTimeout={this.state.connectionTimeout}
                handleCancel={this.handleCancel}
                handleSubscriptionDataRefetch={this.handleSubscriptionDataRefetch}
                subscriptions={this.state.subscriptions}
              />
              <h3 className="text-left mt-4 border-bottom">{texts[this.props.langKey].subscription.customerPortalTitle}</h3>
              <div>
                <p>{texts[this.props.langKey].subscription.customerPortalDescription}</p>
                <CustomerPortalButton
                  langKey={this.props.langKey}
                  jwtToken={this.props.jwtToken}
                />
              </div>
            </div>
            : <div>
              <p>{texts[this.props.langKey].subscription.authenticationFailed}</p>
              <p>{texts[this.props.langKey].subscription.authenticationFailInfo}</p>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default SubscriptionManagement;
