import React from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

/**
 * Simple reusable modal component.
 * 
 * @param {boolean} props.showState `true` to show modal, `false` to hide.
 * @param {Object} props.content Object containing jsx formatted content.
 * @param {jsx} [props.content.title] Pre-formatted title.
 * @param {jsx} [props.content.body] Pre-formatted body.
 * @param {jsx} [props.content.footer] Pre-formatted footer.
 * @param {string} props.closeButtonText Close button's text.
 * @param {function} props.handleClose Function to handle closing the modal.
 */
const SimpleModal = (props) => {
  return (
    <>
      <Modal show={props.showState} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.content.title || ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.content.body || ""}
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <Button variant="primary" onClick={props.handleClose}>
              {props.closeButtonText}
            </Button>
          </div>
          <div className="small row border-top mt-2">
            {props.content.footer || ""}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SimpleModal;
