import React from "react";

//import Label from "./Label";
/*
  props:
  name: objektin nimi (jolla erotellaan muista)
  value: sisältö jota kirjoitetaan
  inputType: esim text tai password
  onChange: handleri joka käsittelee tekstiä
  placeholder: jos ei mitään, rendataan tämä teksti
*/
const Input = (props) => {
  return (
    <div className="form-group col col-sm-10">
      <input
        className={props.clsName}
        autoCorrect="off"
        autoCapitalize="off"
        autoComplete="off"
        id={props.name}
        name={props.name}
        type={props.inputType}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        list={props.list || ""}
      />

      <label htmlFor={props.name} className="floating-label">
        {props.placeholder + (props.optionalText ? ` (${props.optionalText})` : "")}
      </label>
      {props.err}
    </div>
  );
};

export default Input;
