import React from "react";

const FI = {
  body:
    <>
      <p className="mt-3 px-3">Me DjOnlinella tiedämme vuosien kokemuksesta, miten suurta roolia taustamusiikki näyttelee osana palvelukokemusta. Siksi kehitimme StreamFactoryn – selainpohjaisen musiikin suoratoistopalvelun, jonka dynaamiset soittolistat varmistavat oikeanlaisen musiikkitunnelman liiketiloissasi. Voit hallita soittolistoja itse mistä ja milloin tahansa tai jättää musiikin täysin StreamFactoryn huoleksi.</p>
      <p className="px-3">Tilauksen aloittamalla pääset heti kokeilemaan käyttöliittymää sekä lukuisia valmiita soittolistoja. Muutaman päivän kuluessa kokeneet äänisuunnittelijamme kartoittavat tarpeesi tarkemmin, jonka perusteella rakennamme personoidun ja uniikin musiikkisisällön luomaan ainutlaatuisen tunnelman juuri teille.</p>
      <p className="px-3">Kokeile nyt StreamFactorya veloituksetta viikon testijakson ajan. Voit halutessasi lopettaa tilauksen ilman kuluja koska tahansa testijakson aikana. Testijakson jälkeen palvelun hinta on 59 € kuukaudessa.</p>
    </>
}

const EN = {
  body:
    <>
      <p className="mt-3 px-3">...</p>
      <p className="px-3">...</p>
      <p className="px-3">...</p>
    </>
}

// const EXAMPLE = {
//   body:
//     <>
//       <p className="mt-3 px-3"></p>
//       <p className="px-3"></p>
//       <p className="px-3"></p>
//     </>
// }

const texts = { fi: FI, en: EN };

export default texts;
