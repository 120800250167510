import React from "react";
import { WithWizard } from "react-albus";
import { texts } from "./multilanguage";

class Navigation extends React.Component {
  //annetaan parametrina funktion osoite johon hypätään jos kaikki o ok.
  handleNext = async (nextFnc) => {
    //kaikki ok, jotenka voidaan siirtyä seuraavaan.
    const v = await this.props.nextHandler().then((re) => {
      if (re === true) {
        nextFnc();
        return true;
      } else {
        return false;
      }
    });

    return v;
  };

  render() {
    return (
      <WithWizard
        render={({ next, previous, step, steps }) => (
          <div className="navigation-wrapper flex-row-reverse">
            {steps.indexOf(step) < steps.length - 1 && (
              <button
                className="btn btn-custom btn-primary"
                onClick={() => this.handleNext(next)}
                disabled={this.props.isNextDisabled}
              >
                {
                  this.props.isLoadingNext
                    ? <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                    : texts[this.props.langKey].navigation.next
                }
              </button>
            )}

            {steps.indexOf(step) > 0 && (
              <button
                className="btn btn-custom btn-secondary"
                onClick={previous}
                disabled={this.props.isPreviousDisabled}
              >
                {
                  this.props.isLoadingPrevious
                    ? <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                    : texts[this.props.langKey].navigation.previous
                }
              </button>
            )}
          </div>
        )}
      />
    );
  }
}

export default Navigation;
