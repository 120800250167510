// @ts-check

import React, { Component } from "react";
import axios from "axios";
import Input from "../components/Input";
import PasswordInput from "../components/PasswordInput";
import Infolink from "./../components/Infolink";
import Navigation from "./../components/Navigation";
import {
  VALIDATE_USERNAME, VALIDATE_FORM, INPUT_LENGTHS,
  MIN_LENGTH_ERROR_MESSAGE, MAX_LENGTH_ERROR_MESSAGE
} from "../general";
import { texts } from "../components/multilanguage";

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,

      username: props.accountInfo.username,
      password: props.accountInfo.password,
      passwordConfirm: props.accountInfo.passwordConfirm,

      passwordVisibility: {
        password: false,
        passwordConfirm: false
      },

      firstTimeEnteringPassword: true,

      isError: {
        username: "",
        password: "",
        passwordConfirm: "",
      },

      isLoadingNext: false,

      generalError: "",
    };

    this.lastCheckedUsername = "";

    this.usernameCheckTimeoutId = null;
    this.axiosSource = null;

    this.errorPrompts = {};

  }

  setSpecificError = (name, errorValue) => {
    const isError = { ...this.state.isError };
    isError[name] = errorValue;
    this.setState({ isError });
  }

  handleUsernameCheck = (name, value) => {
    clearTimeout(this.usernameCheckTimeoutId);

    if (value.length < 4) {
      return texts[this.props.langKey].errors.value_lt_4;
    }

    if (!VALIDATE_USERNAME(value)) {
      return texts[this.props.langKey].errors.usernameInvalidFormat;
    }

    if (this.lastCheckedUsername !== value) {
      if (this.axiosSource) {
        this.axiosSource.cancel();
      }
      this.usernameCheckTimeoutId = setTimeout(() => {
        console.log("Axios request - checking username:", value);
        this.axiosSource = axios.CancelToken.source();
        axios.get("/api/check-username", {
          params: { username: value },
          cancelToken: this.axiosSource.token,
          timeout: 6000,
        })
          .then(response => {
            console.log("Username free:", value);
            this.lastCheckedUsername = value;
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log("Request cancelled with token:", error);
            }
            else if (error.response) {
              console.log("Username taken:", value);
              if (error.response.data.id === 40005) {
                this.setSpecificError(name, texts[this.props.langKey].errors.usernameInvalidFormat);
              }
              if (error.response.data.id === 40006) {
                this.setSpecificError(name, texts[this.props.langKey].errors.accountAlreadyExists);
              }
              this.lastCheckedUsername = value;
            }
            else if (error.request) {
              console.log("No response/timeout:", error);
            }
            else {
              console.log("General error:", error);
            }
          });
      }, 1500);
    }

    return "";
  }

  checkErrors = (name, value) => {
    switch (name) {
      case "username":
        this.errorPrompts[name] = this.handleUsernameCheck(name, value);
        break;
      case "password":
      case "passwordConfirm":
        if (value.length < INPUT_LENGTHS[name].min) {
          this.errorPrompts[name] = MIN_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else if (value.length > INPUT_LENGTHS[name].max) {
          this.errorPrompts[name] = MAX_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else {
          this.errorPrompts[name] = "";
        }
        break;
      default:
        break;
    }
    return this.errorPrompts[name];
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  //käsitellään eventti kun painetaan nextiä.
  /*
    Tarkistetaan formin sisältö.
  */
  nextHandler = async () => {
    let acco = {
      username: this.state.username,
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
      customerIdToken: "",
    };

    const isError = { ...this.state.isError };

    Object.keys(isError).forEach((key) => {
      isError[key] = this.checkErrors(key, this.state[key]);
    });

    if (!this.doPasswordsMatch()) {
      return false;
    }

    this.setState({
      isError,
    });

    if (VALIDATE_FORM(isError)) {
      this.setState({
        isLoadingNext: true,
      });

      let generalError = "";

      const resStatus = await this.props.checkUser(acco).then((res) => {
        return res;
      });

      if (resStatus === 200) {
        generalError = "";
      } else if (resStatus === 409) {
        generalError = texts[this.props.langKey].errors.accountAlreadyExists;
      } else {
        generalError = texts[this.props.langKey].errors.unknown;
      }

      this.setState({
        generalError
      });

      if (resStatus === 200) {
        return true;
      } else {
        this.setState({
          isLoadingNext: false,
        });
        return false;
      }
    }

    return false;
  };

  doPasswordsMatch = () => {
    return this.state.password === this.state.passwordConfirm;
  }

  handlePasswordMismatch = () => {
    this.setState({
      generalError: this.doPasswordsMatch() ? "" : texts[this.props.langKey].errors.passwordCompareMismatch
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
  };

  formValChange = (e) => {
    e.preventDefault();

    let tempError;
    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      tempError = this.checkErrors(name, value);
    }

    if (name === "username") {
      isError[name] = tempError;
    }
    else if (tempError === "") {
      isError[name] = tempError;
    }

    this.setState({
      [name]: value,
      isError,
    }, () => {
      if (!this.state.firstTimeEnteringPassword) {
        this.handlePasswordMismatch();
      }
      this.setState({ isError });
    });
  };

  formValBlur = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError[name]) {
      return;
    }

    isError[name] = this.checkErrors(name, value);

    if (name === "password" || name === "passwordConfirm") {
      this.setState({
        firstTimeEnteringPassword: false
      });
    }

    this.setState({
      isError,
    });
  };

  togglePasswordVisibility = (targetName) => {
    const passwordVisibility = { ...this.state.passwordVisibility }
    passwordVisibility[targetName] = !passwordVisibility[targetName]
    this.setState({ passwordVisibility })
  }

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);
  }

  componentWillUnmount() {
    clearTimeout(this.usernameCheckTimeoutId);
  }

  render() {
    const { isError } = this.state;

    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.accountForm}</h2>
        <form className="container mt-4" onSubmit={this.onSubmit} noValidate>

          <div className="row justify-content-center">
            {/* Account information */}
            <div className="col-md-12">
              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.username.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="username"
                  inputType="text"
                  value={this.state.username}
                  placeholder={texts[this.props.langKey].placeholders.username}
                  req={true}
                  err={
                    isError.username.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.username}
                      </span>
                    )
                  }
                />
                <Infolink
                  show={true}
                  name="username"
                  tooltipInfo={texts[this.props.langKey].tooltipTexts.username}
                />
              </div>

              <div className="row justify-content-center">
                <PasswordInput
                  clsName={
                    isError.password.length > 0
                      ? "is-invalid form-control password"
                      : "form-control password"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="password"
                  inputType="password"
                  value={this.state.password}
                  placeholder={texts[this.props.langKey].placeholders.password}
                  visible={this.state.passwordVisibility.password}
                  handlePasswordToggle={this.togglePasswordVisibility}
                  err={
                    isError.password.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.password}
                      </span>
                    )
                  }
                />
                <Infolink
                  show={true}
                  name="password"
                  tooltipInfo={texts[this.props.langKey].tooltipTexts.password}
                />
              </div>
              <div className="row justify-content-center">
                <PasswordInput
                  clsName={
                    isError.passwordConfirm.length > 0
                      ? "is-invalid form-control password"
                      : "form-control password"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="passwordConfirm"
                  inputType="password"
                  value={this.state.passwordConfirm}
                  placeholder={
                    texts[this.props.langKey].placeholders.passwordConfirm
                  }
                  visible={this.state.passwordVisibility.passwordConfirm}
                  handlePasswordToggle={this.togglePasswordVisibility}
                  err={
                    isError.passwordConfirm.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.passwordConfirm}
                      </span>
                    )
                  }
                />
                <Infolink
                  show={true}
                  name="passwordConfirm"
                  tooltipInfo={
                    texts[this.props.langKey].tooltipTexts.passwordConfirm
                  }
                />
              </div>

              <div className="row">
                <span className="text-danger"> {this.state.generalError} </span>
              </div>
            </div>
            {/* Account information END */}
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="row justify-content-center">
                <Navigation
                  nextHandler={this.nextHandler}
                  langKey={this.props.langKey}
                  isNextDisabled={this.state.isLoadingNext}
                  isPreviousDisabled={this.state.isLoadingNext}
                  isLoadingNext={this.state.isLoadingNext}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default AccountForm;
