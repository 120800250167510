// @ts-check

import React, { useState, useEffect } from "react";
import SubscriptionCardData from "../components/SubscriptionCardData";
import { texts } from "../components/multilanguage";

const SubscriptionCard = (props) => {
  const [subscriptionEndText, setSubscriptionEndText] = useState("");
  const [canceledOnTrial, setCanceledOnTrial] = useState(false);
  const [canceledOnActive, setCanceledOnActive] = useState(false);

  useEffect(() => {
    const subscriptionEnd = props.subscription.endDate || props.subscription.trialEnd;
    if (subscriptionEnd) {
      setSubscriptionEndText(` (${texts[props.langKey].subscription.table.preAfter}${timeDifferenceToNow(subscriptionEnd)})`);
      const intervalID = setInterval(() => {
        setSubscriptionEndText(` (${texts[props.langKey].subscription.table.preAfter}${timeDifferenceToNow(subscriptionEnd)})`);
      }, 60000);
      return () => {
        clearInterval(intervalID);
      }
    }
  }, [props.subscription, props.langKey]);

  useEffect(() => {
    if (props.subscription.status === "trialing" && !props.subscription.startDate) {
      setCanceledOnTrial(true);
    }
    if (props.subscription.status === "active" && props.subscription.endDate) {
      setCanceledOnActive(true);
    }
  }, [props.subscription]);

  /**
   * Maps Stripe's subscription status to localized texts.
   * @param {string} status Stripe's subscription status.
   * @returns {string}
  */
  function parseStatus(status) {
    if (status === "trialing") {
      return texts[props.langKey].subscription.trial;
    }
    if (status === "active") {
      return texts[props.langKey].subscription.active;
    }
  }

  function convertTimestampToDate(timestamp) {
    if (timestamp) {
      const datetime = new Date(timestamp).toLocaleString(texts[props.langKey].locale);
      return datetime.substring(0, datetime.length - 3);
    }
    return null;
  }

  function timeDifferenceToNow(timestamp) {
    if (timestamp) {
      const timeDifference = timestamp - Date.now();
      const minuteDifference = Math.floor(timeDifference / 1000 / 60);
      const hourDifference = Math.floor(timeDifference / 1000 / 60 / 60);
      const dayDifference = Math.ceil(timeDifference / 1000 / 60 / 60 / 24);

      if (timeDifference < 0) {
        return texts[props.langKey].subscription.table.subscriptionHasEnded;
      }
      if (timeDifference > 30 * 60 * 60 * 1000) {
        return "~" + dayDifference + " " + texts[props.langKey].subscription.table.afterDays;
      }
      if (timeDifference > 1 * 60 * 60 * 1000) {
        return hourDifference + " " + texts[props.langKey].subscription.table.afterHours;
      }
      return minuteDifference + " " + texts[props.langKey].subscription.table.afterMinutes;
    }
    return null;
  }

  return (
    <div className="subscription-card">
      <div className="subscription-card-header">
        <div className="col-auto mr-auto subscription-card-header-text">{props.subscription.productName}</div>
        <div className="col-auto subscription-card-header-text">
          <span className="price-info">{parseInt(props.subscription.price) + " €/"}<span className="d-none d-md-inline">{texts[props.langKey].monthText}</span><span className="d-md-none">{texts[props.langKey].monthAbbr}</span></span>
          <span className="vat-info">({(Math.round(props.subscription.price * 100 * 1.24) / 100).toFixed(2).replace(".", texts[props.langKey].decimalPoint)}{texts[props.langKey].subscription.includingVAT})</span>
        </div>
      </div>
      <div className="subscription-card-body">
        <SubscriptionCardData
          containerClass="subscription-card-data"
          dataHeader={texts[props.langKey].subscription.table.status}
          dataValue={parseStatus(props.subscription.status)}
        />
        <SubscriptionCardData
          containerClass={props.subscription.trialEnd === null ? "d-none" : "subscription-card-data"}
          dataHeader={texts[props.langKey].subscription.table.trialEnd}
          dataValue={convertTimestampToDate(props.subscription.trialEnd) + subscriptionEndText}
        />
        <SubscriptionCardData
          containerClass={props.subscription.startDate === null ? "d-none" : "subscription-card-data"}
          dataHeader={texts[props.langKey].subscription.table.subscriptionStartDate}
          dataValue={
            props.subscription.status === "trialing"
              ? convertTimestampToDate(props.subscription.startDate) + subscriptionEndText
              : convertTimestampToDate(props.subscription.startDate) || "-"}
        />
        <SubscriptionCardData
          containerClass={props.subscription.endDate === null ? "d-none" : "subscription-card-data"}
          dataHeader={texts[props.langKey].subscription.table.subscriptionEndDate}
          dataValue={convertTimestampToDate(props.subscription.endDate) + subscriptionEndText || "-"}
        />
        {canceledOnTrial
          ? <div className="mb-2">
            <span className="fa fa-exclamation-circle mx-1"></span>
            {texts[props.langKey].subscription.table.subscriptionWillEndAfterTrial}
          </div>
          : false
        }
      </div>
      <div className="subscription-card-footer">
        <div className="col text-right">
          <button onClick={(e) => props.handleCancel(e, props.subscription.priceId)}
            type="button"
            className={(canceledOnTrial || canceledOnActive) ? "btn btn-secondary btn-xs invisible " : "btn btn-secondary btn-xs"}
            disabled={props.disableControl}>
            {texts[props.langKey].subscription.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
