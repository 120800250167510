import React, { useState, useEffect } from "react";

const Datalist = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(props.list.map(listElem => (
      <option value={listElem[props.valueName]} key={listElem[props.keyName]} />
    )));
  }, [props.list]);

  return (
    <datalist id={props.id}>
      {list}
    </datalist>
  )
}

export default Datalist;
