// @ts-check

import React, { Component } from "react";
import axios from "axios";
import ConfirmationModal from "../components/ConfirmationModal";
import { INPUT_LENGTHS, MIN_LENGTH_ERROR_MESSAGE, MAX_LENGTH_ERROR_MESSAGE } from "../general";
import { texts } from "../components/multilanguage";

class SucceedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,
      email: props.email,
      confirmationModal: false,
      musicWishFormValue: "",
      formPhaseDone: false,

      isLoading: false,
      loadingSkip: false,
      loadingSend: false,

      error: "",
      isError: {
        musicWishFormValue: "",
      }
    };
  }

  handleSkip = async (e) => {
    e.preventDefault();
    if (this.state.musicWishFormValue) {
      this.setState({
        confirmationModal: true
      });
    }
    else {
      this.setState({
        loadingSkip: true
      });
      await this.sendMessage("");
    }
  };

  handleConfirmationModalClose = () => {
    this.setState({
      confirmationModal: false
    });
  };

  handleConfirmationModalChoice = async (choice) => {
    this.setState({
      confirmationModal: false,
    });
    if (choice) {
      this.setState({
        loadingSkip: true
      });
      await this.sendMessage("");
    }
  }

  handleFormChange = (e) => {
    this.setState({
      musicWishFormValue: e.target.value
    });
  };

  checkErrors = (name, value) => {
    switch (name) {
      case "musicWishMessage":
        if (value.length < INPUT_LENGTHS[name].min) {
          return MIN_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else if (value.length > INPUT_LENGTHS[name].max) {
          return MAX_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else {
          return "";
        }
      default:
        break;
    }
    return "";
  };

  checkIfValid = (message) => {
    const error = this.checkErrors("musicWishMessage", message);
    if (error) {
      this.setState({
        error: error
      });
      return false;
    }
    return true;
  }

  sendMessage = async (message) => {
    if (!this.checkIfValid(message)) {
      this.setState({
        loadingSkip: false,
        loadingSend: false
      });
      return false;
    }

    this.setState({
      isLoading: true
    });
    try {
      const response = await axios({
        method: "post",
        url: "/api/message",
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 10000,
        data: {
          customerIdToken: this.props.customerIdToken,
          usersIdToken: this.props.usersIdToken,
          message: message,
        },
      });
      this.setState({
        formPhaseDone: true
      });
    }
    catch (error) {
      this.setState({
        isLoading: false,
        loadingSkip: false,
        loadingSend: false,
        error: texts[this.props.langKey].errors.network,
      });
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loadingSend: true
    });
    await this.sendMessage(this.state.musicWishFormValue);
  }

  navigateToSubscriptionManagement = () => {
    this.props.succeed();
  }

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);
  }

  render() {
    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.succeedForm}</h2>
        {this.state.formPhaseDone
          ? (<div style={{ margin: "40px 0px 10px" }}>
            <div className="d-grid gap-2 col-md-12">
              <a type="button" className="btn btn-primary btn-lg" href={this.props.streamFactoryUrl + "?registered=true"}>{texts[this.props.langKey].navigation.proceedToStreamFactory}</a>
              <button type="button" className="btn btn-secondary btn-sm" onClick={this.navigateToSubscriptionManagement}>{texts[this.props.langKey].navigation.proceedToSubscriptionManagement}</button>
            </div>
          </div>)
          : (<form className="container-fluid" onSubmit={this.handleFormSubmit}>
            <p>
              {texts[this.props.langKey].titles.labels.succeed_label_pt1}
              {this.state.email}
            </p>
            <div className="mt-4">
              <label htmlFor="musicWishForm" className="form-control-plaintext">{texts[this.props.langKey].titles.labels.musicForm}</label>
              <textarea
                className="form-control"
                id="musicWishForm"
                rows={3}
                value={this.state.musicWishFormValue}
                onChange={this.handleFormChange}
              >
              </textarea>
            </div>
            <div className="error-container text-danger text-center mt-2" role="alert">
              {this.state.error}
            </div>
            <div className="row justify-content-center">
              <div className="navigation-wrapper flex-row-reverse">
                <button
                  type="submit"
                  className="btn btn-custom btn-primary"
                  disabled={this.state.isLoading}
                >
                  {
                    this.state.loadingSend
                      ? <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                      : texts[this.props.langKey].navigation.send
                  }
                </button>
                <button
                  type="button"
                  className="btn btn-custom btn-secondary"
                  onClick={this.handleSkip}
                  disabled={this.state.isLoading}
                >
                  {
                    this.state.loadingSkip
                      ? <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                      : texts[this.props.langKey].navigation.skip
                  }
                </button>
              </div>
            </div>

            <ConfirmationModal
              showState={this.state.confirmationModal}
              promptText={texts[this.props.langKey].confirmationModal.promptText}
              buttonCancelText={texts[this.props.langKey].confirmationModal.buttonCancel}
              buttonAgreeText={texts[this.props.langKey].confirmationModal.buttonAgree}
              handleClose={this.handleConfirmationModalClose}
              handleChoice={this.handleConfirmationModalChoice}
            />
          </form>)}
      </>
    );
  }
}

export default SucceedForm;
