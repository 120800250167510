import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Infolink = (props) => {
  if (props.display === "none") {
    return (null);
  }
  if (props.show) {
    return (
      <div className="col-form-label">
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{props.tooltipInfo}</Tooltip>}
          flip={true}
        >
          <span className="fa fa-question-circle-o">
          </span>
        </OverlayTrigger>
      </div>
    );
  }
  return (
    <div className="col-form-label">
      <span className="fa fa-question-circle-o text-transparent">
      </span>
    </div>
  );
};

export default Infolink;
