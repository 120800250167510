// @ts-check

import React, { Component } from "react";
import Input from "../components/Input";
import Infolink from "../components/Infolink";
import Label from "../components/Label";
import Navigation from "../components/Navigation";
import Datalist from "../components/Datalist";
import {
  VALIDATE_FORM, VALIDATE_ZIP, VALIDATE_VAT_ID, INPUT_LENGTHS,
  MIN_LENGTH_ERROR_MESSAGE, MAX_LENGTH_ERROR_MESSAGE
} from "../general";
import { texts } from "../components/multilanguage";

class BillingInformationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,

      companyName: props.newUser.companyName,
      companyVatId: props.newUser.companyVatId,
      companyAddress: props.newUser.companyAddress,
      companyZip: props.newUser.companyZip,
      companyCity: props.newUser.companyCity,

      isError: {
        companyName: "",
        companyVatId: "",
        companyAddress: "",
        companyZip: "",
        companyCity: "",
      },
    };

    this.errorPrompts = {};
  }

  checkErrors = (name, value) => {
    switch (name) {
      case "companyName":
      case "companyAddress":
      case "companyCity":
        if (value.length < INPUT_LENGTHS[name].min) {
          this.errorPrompts[name] = MIN_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else if (value.length > INPUT_LENGTHS[name].max) {
          this.errorPrompts[name] = MAX_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else {
          this.errorPrompts[name] = "";
        }
        break;
      case "companyVatId":
        this.errorPrompts[name] = VALIDATE_VAT_ID(value) ? "" : texts[this.props.langKey].errors.vatIdInvalid;
        break;
      case "companyZip":
        this.errorPrompts[name] = VALIDATE_ZIP(value) ? "" : texts[this.props.langKey].errors.zipInvalid;
        break;
      default:
        break;
    }
    return this.errorPrompts[name];
  };

  getUserDataFromState = (
    { companyName, companyVatId, companyAddress, companyZip, companyCity }) => {
    return {
      companyName, companyVatId, companyAddress, companyZip, companyCity
    }
  }

  //käsitellään eventti kun painetaan nextiä.
  /*
    Tarkistetaan formin sisältö.
  */
  nextHandler = async (e) => {
    //nyt voidaan tarkemmin katsoa mitkä failasi ja esittää ne käyttäjälle
    const isError = { ...this.state.isError };

    Object.keys(isError).forEach((key) => {
      isError[key] = this.checkErrors(key, this.state[key]);
    });

    this.setState({
      isError
    });

    if (VALIDATE_FORM(isError)) {
      this.props.addUserData(this.getUserDataFromState(this.state));
      return true;
    }
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  formValChange = (e) => {
    e.preventDefault();

    let tempError;
    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      tempError = this.checkErrors(name, value);
    }

    if (tempError === "")
      isError[name] = tempError;

    this.setState({
      [name]: value,
      isError,
    });
  };

  formValBlur = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      isError[name] = this.checkErrors(name, value);
    }

    this.setState({
      isError,
    });
  };

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);
  }

  render() {
    const { isError } = this.state;

    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.billingInformationForm}</h2>
        <form className="container" onSubmit={this.onSubmit} noValidate>

          <div className="row justify-content-center">
            {/* Company */}
            <div className="col-md-12">
              <div className="row">
                <Label title={texts[this.props.langKey].titles.labels.company} />
              </div>
              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.companyName.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="companyName"
                  inputType="text"
                  value={this.state.companyName}
                  placeholder={texts[this.props.langKey].placeholders.companyName}
                  err={
                    isError.companyName.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.companyName}
                      </span>
                    )
                  }
                />
                <Infolink
                  display="none"
                  show={false}
                />
              </div>

              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.companyVatId.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="companyVatId"
                  inputType="text"
                  value={this.state.companyVatId}
                  placeholder={texts[this.props.langKey].placeholders.companyVatId}
                  err={
                    isError.companyVatId.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.companyVatId}
                      </span>
                    )
                  }
                />
                <Infolink
                  display="none"
                  show={false}
                />
              </div>

              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.companyAddress.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="companyAddress"
                  inputType="text"
                  value={this.state.companyAddress}
                  placeholder={texts[this.props.langKey].placeholders.companyAddress}
                  err={
                    isError.companyAddress.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.companyAddress}
                      </span>
                    )
                  }
                />
                <Infolink
                  display="none"
                  show={false}
                />
              </div>

              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.companyZip.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="companyZip"
                  inputType="text"
                  value={this.state.companyZip}
                  placeholder={texts[this.props.langKey].placeholders.companyZip}
                  err={
                    isError.companyZip.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.companyZip}
                      </span>
                    )
                  }
                />
                <Infolink
                  display="none"
                  show={false}
                />
              </div>

              <div className="row justify-content-center">
                <Input
                  clsName={
                    isError.companyCity.length > 0
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  onChange={this.formValChange}
                  onBlur={this.formValBlur}
                  name="companyCity"
                  inputType="text"
                  value={this.state.companyCity}
                  placeholder={texts[this.props.langKey].placeholders.companyCity}
                  list="cityList"
                  err={
                    isError.companyCity.length > 0 && (
                      <span className="invalid-feedback">
                        {isError.companyCity}
                      </span>
                    )
                  }
                />
                <Infolink
                  display="none"
                  show={false}
                />
                <Datalist
                  id="cityList"
                  list={this.props.cityList}
                  keyName="id"
                  valueName="city"
                />
              </div>
            </div>
            {/* Company END */}
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="row justify-content-center">
                <Navigation
                  nextHandler={this.nextHandler}
                  langKey={this.props.langKey}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default BillingInformationForm;
