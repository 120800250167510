// @ts-check

import React from "react";

const SubscriptionCardData = (props) => {
  return (
    <div className={props.containerClass}>
      <div className="subscription-card-data-header">{props.dataHeader}</div>
      <div>{props.dataValue}</div>
    </div>
  );
};

export default SubscriptionCardData;
