// @ts-check

import React, { Component } from "react";
import Navigation from "../components/Navigation";
import welcomePageTexts from "../welcomePageTexts";
import { texts } from "../components/multilanguage";

class LocationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,
    };

    this.errorPrompts = {};
  }

  nextHandler = async (e) => {
    return true;
  };

  reloadPage = () => {
    window.location.reload();
  }

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);
  }

  render() {
    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.welcomeForm}</h2>
        {this.props.loadingError
          ? <div className="m-4">{texts[this.props.langKey].errors.generatingForm} <button type="button" className="btn btn-primary btn-sm" onClick={this.reloadPage}>{texts[this.props.langKey].buttons.refresh}</button></div>
          : <div className="container">
            <div className="row">
              {welcomePageTexts[this.props.langKey].body}
            </div>
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="row justify-content-end">
                  <Navigation
                    nextHandler={this.nextHandler}
                    langKey={this.props.langKey}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default LocationForm;
