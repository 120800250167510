import React from "react";

const FI = {
  title:
    <>
      REKISTERI- JA TIETOSUOJASELOSTE
    </>,
  body:
    <>
      <h4>Kutalab Oy:n asiakasrekisterin tietosuojaseloste</h4>
      <p>Tämä on henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste</p>
      <p>Päivitetty 23.8.2021</p>

      <h4>1. REKISTERINPITÄJÄ</h4>
      <p>Kutalab Oy<br />Y-tunnus: 1458259-0<br />Vammaksentie 1<br />38210 Sastamala</p>

      <h4>2. YHTEYSHENKILÖ REKISTERIÄ KOSKEVISSA ASIOISSA</h4>
      <p>Pablo Harju<br />pablo@djonline.fi<br />010 281 7200</p>

      <h4>3. REKISTERIN NIMI</h4>
      <p>Kutalab Oy:n asiakasrekisteri</p>
      <p>Kutalab Oy:n asiakasrekisteriin tallennettuja henkilötietoja käsitellään Kutalab Oy:n ja sen asiakkaiden välisten asiakassuhteiden hoitamiseen (henkilötietolaki 8 § 1 momentti 5 kohta).</p>

      <h4>4. REKISTERIN TIETOSISÄLTÖ</h4>
      <p>Kutalab Oy:n asiakasrekisteriin voidaan tallettaa seuraavia tietoja Kutalab Oy:n asiakkaista:</p>
      <ul>
        <li>etu-ja sukunimet</li>
        <li>osoite</li>
        <li>puhelinnumerot</li>
        <li>y-tunnukset</li>
        <li>sähköpostiosoitteet</li>
        <li>asiakkaalle kohdistetut edut ja kampanjat sekä niiden käyttö</li>
        <li>suoramarkkinointiluvat ja -kiellot sekä markkinoinninkohdentamiseen liittyvät tiedot</li>
        <li>Kutalab Oy:n asiakasrekisteriin talletettujen henkilötietojen luovutusluvat ja -kiellot</li>
      </ul>
      <p>Tietoja käsitellään sovittujen palvelujen tuottamiseen, palvelujen ja tuotteiden toimittamiseen asiakkaalle, tuotteiden ja palvelujen kehittämiseen, laskutukseen, asiakassuhteen hoitamiseen ja kehittämiseen liittyen sekä tilastollisiin tarkoituksiin.</p>

      <h4>5. SÄÄNNÖNMUKAISET TIETOLÄHTEET</h4>
      <p>Kutalab Oy:n asiakasrekisteriin talletetut henkilötiedot saadaan asiakkailta itseltään sekä luovutuksina Kutalab Oy:n yhteistyökumppaneilta, jos ne ovat saaneet luovutukseen asiakkaan luvan.</p>

      <h4>6. SÄÄNNÖNMUKAISET TIETOJEN LUOVUTUKSET</h4>
      <p>Kutalab Oy voi luovuttaa Kutalab Oy:n asiakasrekisteriin talletettuja asiakkaidensa henkilötietoja yhteistyökumppaneilleen asennus- ja palvelutehtäviin henkilötietolain (523/1999) ja sähköisen viestinnän tietosuojalain (516/2004) mukaisesti.</p>

      <h4>7. TIETOJEN SIIRTO EU:N TAI ETA:N ULKOPUOLELLE</h4>
      <p>Tietoja ei siirretä EU:n tai ETA:n ulkopuolelle paitsi mahdollisesti silloin kun tilaus on tehty maksukortilla ja maksutapahtumia hallinnoi Stripe Payments Europe Ltd.</p>

      <h4>8. REKISTERIN SUOJAUKSEN PERIAATTEET</h4>
      <p>Kutalab Oy:n asiakasrekisterin tiedot on tallennettu Kutalab Oy:n sähköiseen käyttöjärjestelmään, joka on suojattu käyttöjärjestelmän suojausohjelmiston, palomuurien ja muiden teknisten keinojen avulla. Järjestelmään sisäänpääsy edellyttää käyttäjätunnuksen ja salasanan syöttämistä. Järjestelmään tallennettuihin rekisterin sisältämiin tietoihin pääsevät ja niitä ovat oikeutettuja käyttämään vain Kutalab Oy:n henkilökunta. Osittain asiakkaan tietoja löytyy myös paperisena sopimusten muodossa. Nämä on arkistoitu ja niitä säilytetään turvallisessa paikassa.</p>

      <h4>9. MAKSUKORTEILLA TEHDYT TILAUKSET</h4>
      <p>Maksukorteilla tehtävissä tilauksissa maksutapahtumien käsittelyssä ja hallinnoinnissa on käytössä Stripe Payments Europe Ltd. Asiakkaan on annettava suostumuksensa maksutietojen keräämiseen ja tallentamiseen sekä toistuvien maksujen automaattiseen lunastamiseen tilauksen yhteydessä ilmoitetulta maksutililtä. Stripe Payments Europe Limited, Irlanti, käsittelee Asiakkaan luottokorttitietoja DjOnline-sovelluksessa tehtyjen maksutapahtumien hallinnointia varten. Tarkempia tietoja Stripen käsittelemien tietojen tietosuojasta on Stripen tietosuojakäytännössä (Stripe Privacy Policy: <a href="https://stripe.com/fi/privacy" target="_blank" rel="noopener noreferrer">stripe.com/fi/privacy</a>).</p>

      <h4>10. REKISTERÖIDYN KIELTO-OIKEUS</h4>
      <p>Kutalab Oy:n asiakasrekisteriin rekisteröidyllä asiakkaalla on oikeus kieltää Kutalab Oy:ta käsittelemästä ja/tai luovuttamasta häntä itseään koskevia tietoja suoramainontaa tai etämyyntiä koskevista asioista. Kiellon voi tehdä joko kirjallisesti postittamalla allekirjoitetun kiellon Kutalab Oy:n postiosoitteeseen tai lähettämällä sähköpostia asiakaspalveluun myynti@djonline.fi.</p>

      <h4>11. REKISTERÖIDYN TARKASTUS OIKEUS</h4>
      <p>Kutalab Oy:n asiakasrekisterissä olevalla asiakkaalla on oikeus tarkastaa rekisteriin tallennetut itseään koskevat tiedot ja saada niistä kopiot. Asiakas voi tehdä tarkastuspyynnön joko kirjallisesti postittamalla allekirjoitetun kiellon Kutalab Oy:n postiosoitteeseen tai lähettämällä sähköpostia asiakaspalveluun myynti@djonline.fi.</p>

      <h4>12. TIEDON KORJAAMINEN TAI POISTAMINEN</h4>
      <p>Kutalab Oy korjaa, poistaa tai täydentää asiakasrekisterissään olevan, käsittelyn tarkoituksen kannalta virheellisen, tarpeettoman, puutteellisen tai vanhentuneen asiakastiedon oma-aloitteisesti tai rekisteröidyn asiakkaan vaatimuksesta.</p>
    </>,
  footer:
    <>
      <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
      <div className="text-center col-12 mb-2">Copyright Kutalab Oy, kaikki oikeudet pidätetään. Muutokset mahdollisia.</div>
    </>
}


const EN = {
  title:
    <>
      PRIVACY POLICY
    </>,
  body:
    <>
      <h4></h4>
      <p>...</p>

      <h4></h4>
      <p>...</p>

      <h4></h4>
      <p>...</p>

      <h4></h4>
      <p>...</p>
      <p>...</p>

      <h4></h4>
      <p>...</p>

      <h4></h4>
      <p>...</p>
    </>,
  footer:
    <>
      <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
      <div className="text-center col-12 mb-2">Copyright Kutalab Oy, all rights reserved. Changes are possible.</div>
    </>
}

// const EXAMPLE = {
//   title:
//     <>
//       Terms of Delivery 27.04.2021
//     </>,
//   body:
//     <>
//       <h4>Delivered services</h4>
//       <p>...</p>

//       <h4>Vendor's services and response times</h4>
//       <p>...</p>

//       <h4>Vendor's rights and responsibilites</h4>
//       <p>...</p>
//       <p>...</p>

//       <h4>Customer's rights and responsibilites</h4>
//       <p>...</p>
//       <p>...</p>

//       <h4>Length of contract and term of notice</h4>
//       <p>...</p>

//       <h4>Applied law, mediation of disputes</h4>
//       <p>...</p>
//     </>,
//   footer:
//     <>
//       <div className="text-center col-12 my-2">DJ Online | Kutalab Oy | Vammaksentie 1 | 38210 Sastamala | Y-tunnus 1458259-0 | www.djonline.fi</div>
//       <div className="text-center col-12 mb-2">Copyright Kutalab Oy, all rights reserved. Changes are possible.</div>
//     </>
// }

const texts = { fi: FI, en: EN };

export default texts;
