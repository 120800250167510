// @ts-check

import React from "react";
import SubscriptionCard from "../components/SubscriptionCard"
import { texts } from "../components/multilanguage";

const SubscriptionItems = (props) => {
  const subscriptionCards = props.subscriptions.map((subscription, index) => {
    return <SubscriptionCard
      key={index}
      langKey={props.langKey}
      subscription={subscription}
      handleCancel={props.handleCancel}
      disableControl={props.loadingSubscriptionData || props.subscriptionUndergoingChanges}
    ></SubscriptionCard>
  });

  return (
    <>
      <div className={props.loadingSubscriptionData ? "opacity60" : ""}>
        {subscriptionCards.length !== 0
          ? subscriptionCards
          : <div className={props.loadingSubscriptionData ? "d-none" : ""}>{texts[props.langKey].subscription.noActiveSubscriptions}</div>}
      </div>

      {props.subscriptionUndergoingChanges
        ? <div className="alert alert-secondary" role="alert">
          <div>{texts[props.langKey].subscription.subscriptionUndergoingChanges}</div>
          <button className="btn btn-sm btn-info mt-2" onClick={() => { props.handleSubscriptionDataRefetch() }}>{texts[props.langKey].subscription.refresh}</button>
        </div>
        : false}

      {props.connectionTimeout
        ? <div className="alert alert-secondary" role="alert">
          <div>{texts[props.langKey].subscription.connectionTimeout}</div>
          <button className="btn btn-sm btn-info mt-2" onClick={() => { props.handleSubscriptionDataRefetch() }}>{texts[props.langKey].subscription.refresh}</button>
        </div>
        : false}
    </>
  );
};

export default SubscriptionItems;
