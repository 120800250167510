// @ts-check

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { texts } from "../components/multilanguage";

const CustomerPortalButton = (props) => {
  const [customerPortalUrl, setCustomerPortalUrl] = useState("");
  const [isButtonDisabled, setIsButtonDisable] = useState(false);
  const [isError, setIsError] = useState(false);
  const customerPortalLinkRef = useRef(null);

  useEffect(() => {
    function clickCustomerPortalUrl() {
      customerPortalLinkRef.current.click();
    }
    if (customerPortalUrl) {
      clickCustomerPortalUrl();
    }
  }, [customerPortalUrl]);

  function getCustomerPortalUrl() {
    const headers = { "Authorization": `Bearer ${props.jwtToken}` };
    setIsButtonDisable(true);
    axios.get("/api/customer-portal", { headers })
      .then(res => {
        setIsError(false);
        setCustomerPortalUrl(res.data.url);
      })
      .catch(err => {
        setIsError(true);
      })
      .finally(() => {
        setIsButtonDisable(false);
      });
  }

  return (
    <div>
      <button
        className="btn btn-sm btn-secondary ml-2"
        onClick={getCustomerPortalUrl}
        disabled={isButtonDisabled}
      >
        {texts[props.langKey].subscription.customerPortalButton}
      </button>
      {
        customerPortalUrl ? (
          <a
            className="d-none"
            href={customerPortalUrl}
            ref={customerPortalLinkRef}
          >{customerPortalUrl}</a>
        ) : false
      }
      <div className={isError ? "ml-2 mt-1 small" : "d-none"}>{texts[props.langKey].subscription.connectionTimeout}</div>
    </div >
  );
};

export default CustomerPortalButton;
