// @ts-check

import React, { Component } from "react";
import axios from "axios";
import Input from "../components/Input";
import Infolink from "../components/Infolink";
import Label from "../components/Label";
import SimpleModal from "../components/SimpleModal";
import Navigation from "../components/Navigation";
import Datalist from "../components/Datalist";
import {
  VALIDATE_FORM, VALIDATE_ZIP, VALIDATE_EMAIL, VALIDATE_PHONE, INPUT_LENGTHS,
  MIN_LENGTH_ERROR_MESSAGE, MAX_LENGTH_ERROR_MESSAGE, REQUIRED_USER_INFO
} from "../general";
import termsOfUseTexts from "../termsOfUseTexts";
import privacyPolicyTexts from "../privacyPolicyTexts";
import { texts } from "../components/multilanguage";

class LocationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: props.langKey,

      locationName: props.newUser.locationName,
      locationSpace: props.newUser.locationSpace,
      locationAddress: props.newUser.locationAddress,
      locationZip: props.newUser.locationZip,
      locationCity: props.newUser.locationCity,

      contactName: props.newUser.contactName,
      contactTitle: props.newUser.contactTitle,
      contactPhone: props.newUser.contactPhone,
      contactEmail: props.newUser.contactEmail,

      termsOfService: props.newUser.termsOfService,
      marketing: props.newUser.marketing,

      termsOfUseModalShow: false,
      privacyPolicyModalShow: false,

      isError: {
        locationName: "",
        locationSpace: "",
        locationAddress: "",
        locationZip: "",
        locationCity: "",

        contactName: "",
        contactTitle: "",
        contactPhone: "",
        contactEmail: "",

        termsOfService: "",
      },

      loadingError: false,
    };

    this.errorPrompts = {};
  }

  checkErrors = (name, value) => {
    switch (name) {
      // Required fields
      case "locationName":
      case "locationAddress":
      case "contactName":

      // Optional fields
      case "locationSpace":
      case "contactTitle":
        if (value.length < INPUT_LENGTHS[name].min) {
          this.errorPrompts[name] = MIN_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
          if (!REQUIRED_USER_INFO[name] && value.length === 0) {
            this.errorPrompts[name] = "";
          }
        }
        else if (value.length > INPUT_LENGTHS[name].max) {
          this.errorPrompts[name] = MAX_LENGTH_ERROR_MESSAGE(this.props.langKey, name);
        }
        else {
          this.errorPrompts[name] = "";
        }
        break;

      // Fields with custom validation
      case "locationZip":
        this.errorPrompts[name] = VALIDATE_ZIP(value) ? "" : texts[this.props.langKey].errors.zipInvalid;
        break;
      case "locationCity":
        this.errorPrompts[name] = this.isValidCity(value) ? "" : texts[this.props.langKey].errors.cityInvalid;
        break;
      case "contactPhone":
        this.errorPrompts[name] = VALIDATE_PHONE(value) ? "" : texts[this.props.langKey].errors.phoneNumberInvalid;
        break;
      case "contactEmail":
        this.errorPrompts[name] = VALIDATE_EMAIL(value) ? "" : texts[this.props.langKey].errors.emailInvalid;
        break;
      case "termsOfService":
        this.errorPrompts[name] = value === false ? texts[this.props.langKey].errors.termsOfService : "";
        break;
      default:
        break;
    }
    return this.errorPrompts[name];
  };

  getUserDataFromState = (
    { locationName, locationSpace, locationAddress, locationZip, locationCity,
      contactName, contactTitle, contactPhone, contactEmail,
      termsOfService, marketing }) => {
    return {
      locationName, locationSpace, locationAddress, locationZip, locationCity,
      contactName, contactTitle, contactPhone, contactEmail,
      termsOfService, marketing
    }
  }

  //käsitellään eventti kun painetaan nextiä.
  /*
    Tarkistetaan formin sisältö.
  */
  nextHandler = async (e) => {
    //nyt voidaan tarkemmin katsoa mitkä failasi ja esittää ne käyttäjälle
    const isError = { ...this.state.isError };

    Object.keys(isError).forEach((key) => {
      isError[key] = this.checkErrors(key, this.state[key]);
    });

    this.setState({
      isError
    });

    if (VALIDATE_FORM(isError)) {
      this.props.addUserData(this.getUserDataFromState(this.state));
      return true;
    }
    return false;
  };

  handleCheckboxTick = (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      isError[name] = this.checkErrors(name, value);
    }

    this.setState({
      [name]: value,
      isError,
    });
  };

  isValidCity = (city) => {
    if (this.props.cityList.some((cityElem) => city.toLowerCase() === cityElem.city.toLowerCase())) {
      return true;
    }
    return false;
  }

  reloadPage = () => {
    window.location.reload();
  }

  onSubmit = (e) => {
    e.preventDefault();
  };

  formValChange = (e) => {
    e.preventDefault();

    let tempError;
    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      tempError = this.checkErrors(name, value);
    }

    if (tempError === "")
      isError[name] = tempError;

    this.setState({
      [name]: value,
      isError,
    });
  };

  formValBlur = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      isError[name] = this.checkErrors(name, value);
    }

    this.setState({
      isError,
    });
  };

  handleTermsOfUseModalToggle = (e) => {
    if (e) e.preventDefault()
    this.setState({ termsOfUseModalShow: !this.state.termsOfUseModalShow })
  }

  handlePrivacyPolicyModalToggle = (e) => {
    if (e) e.preventDefault()
    this.setState({ privacyPolicyModalShow: !this.state.privacyPolicyModalShow })
  }

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);

    if (!this.props.stripePublishableKey) {
      axios.get("/api/configs/pk_key", { timeout: 7000 })
        .then(res => {
          console.log("Stripe's publishable key received:", res.data.stripePublishableKey);
          this.props.setStripePublishableKey(res.data.stripePublishableKey);
          this.props.loadStripeLibrary(this.props.langKey);
        })
        .catch(err => {
          console.log("Error fetching stripePublishableKey.");
          this.setState({ loadingError: true });
        });
    }

    if (this.props.cityList.length === 0) {
      axios.get("/api/cities", { timeout: 10000 })
        .then(res => {
          this.props.setCityList(res.data.cities);
        })
        .catch(err => {
          console.log("Error fetching city list.");
          this.setState({ loadingError: true });
        });
    }

    if (Object.keys(this.props.productData).length === 0) {
      axios.get("/api/configs/product_data", { timeout: 7000 })
        .then(res => {
          this.props.setProductData(res.data);
        })
        .catch(err => {
          console.log("Error fetching product data.");
          this.setState({ loadingError: true });
        });
    }
  }

  render() {
    const { isError } = this.state;

    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.locationForm}</h2>
        {this.state.loadingError || this.props.loadingError
          ? <div className="m-4">{texts[this.props.langKey].errors.generatingForm} <button type="button" className="btn btn-primary btn-sm" onClick={this.reloadPage}>{texts[this.props.langKey].buttons.refresh}</button></div>
          : <form className="container" onSubmit={this.onSubmit} noValidate>
            <div className="row justify-content-center">
              {/* Location */}
              <div className="col-md-6">
                <div className="row">
                  <Label title={texts[this.props.langKey].titles.labels.location} />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.locationName.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="locationName"
                    inputType="text"
                    value={this.state.locationName}
                    placeholder={texts[this.props.langKey].placeholders.locationName}
                    err={
                      isError.locationName.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.locationName}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="locationName"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.locationName}
                  />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.locationSpace.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="locationSpace"
                    inputType="text"
                    value={this.state.locationSpace}
                    placeholder={texts[this.props.langKey].placeholders.locationSpace}
                    optionalText={texts[this.props.langKey].placeholders.optional}
                    err={
                      isError.locationSpace.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.locationSpace}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="locationSpace"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.locationSpace}
                  />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.locationAddress.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="locationAddress"
                    inputType="text"
                    value={this.state.locationAddress}
                    placeholder={texts[this.props.langKey].placeholders.locationAddress}
                    err={
                      isError.locationAddress.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.locationAddress}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="locationAddress"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.locationAddress}
                  />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.locationZip.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="locationZip"
                    inputType="text"
                    value={this.state.locationZip}
                    placeholder={texts[this.props.langKey].placeholders.locationZip}
                    err={
                      isError.locationZip.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.locationZip}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={false}
                  />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.locationCity.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="locationCity"
                    inputType="text"
                    value={this.state.locationCity}
                    placeholder={texts[this.props.langKey].placeholders.locationCity}
                    list="cityList"
                    err={
                      isError.locationCity.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.locationCity}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={false}
                  />
                  <Datalist
                    id="cityList"
                    list={this.props.cityList}
                    valueName="city"
                    keyName="id"
                  />
                </div>
              </div>
              {/* Location END */}

              {/* Contact person */}
              <div className="col-md-6">
                <div className="row">
                  <Label title={texts[this.props.langKey].titles.labels.contactPerson} />
                </div>
                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.contactName.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="contactName"
                    inputType="text"
                    value={this.state.contactName}
                    placeholder={texts[this.props.langKey].placeholders.contactName}
                    err={
                      isError.contactName.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.contactName}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="contactName"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.contactName}
                  />
                </div>

                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.contactTitle.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="contactTitle"
                    inputType="text"
                    value={this.state.contactTitle}
                    placeholder={texts[this.props.langKey].placeholders.contactTitle}
                    optionalText={texts[this.props.langKey].placeholders.optional}
                    err={
                      isError.contactTitle.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.contactTitle}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="contactTitle"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.contactTitle}
                  />
                </div>

                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.contactPhone.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="contactPhone"
                    inputType="text"
                    value={this.state.contactPhone}
                    placeholder={texts[this.props.langKey].placeholders.contactPhone}
                    err={
                      isError.contactPhone.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.contactPhone}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="contactPhone"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.contactPhone}
                  />
                </div>

                <div className="row justify-content-center">
                  <Input
                    clsName={
                      isError.contactEmail.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    onChange={this.formValChange}
                    onBlur={this.formValBlur}
                    name="contactEmail"
                    inputType="email"
                    value={this.state.contactEmail}
                    placeholder={texts[this.props.langKey].placeholders.contactEmail}
                    err={
                      isError.contactEmail.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.contactEmail}
                        </span>
                      )
                    }
                  />
                  <Infolink
                    show={true}
                    name="contactEmail"
                    tooltipInfo={texts[this.props.langKey].tooltipTexts.contactEmail}
                  />
                </div>

                {/* Checkboxes */}
                <div className="row justify-content-center">
                  <div className="form-group col col-md-10">
                    {/* Not using Checkbox-component for "terms of use" checkbox because issues
                    with formatting label text. */}
                    <div className="custom-control custom-checkbox">
                      <input
                        className={isError.termsOfService.length > 0
                          ? "is-invalid custom-control-input"
                          : "custom-control-input"}
                        type="checkbox"
                        id="termsOfService"
                        name="termsOfService"
                        checked={this.state.termsOfService}
                        onChange={this.handleCheckboxTick}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="termsOfService"
                        title={texts[this.props.langKey].titles.labels.TOSandPrivacy_1
                          + texts[this.props.langKey].titles.labels.TOSandPrivacy_2
                          + texts[this.props.langKey].titles.labels.TOSandPrivacy_3
                          + texts[this.props.langKey].titles.labels.TOSandPrivacy_4
                        }
                      >
                        {texts[this.props.langKey].titles.labels.TOSandPrivacy_1}
                        <a href="#" onClick={this.handleTermsOfUseModalToggle}>
                          {texts[this.props.langKey].titles.labels.TOSandPrivacy_2}
                        </a>
                        {texts[this.props.langKey].titles.labels.TOSandPrivacy_3}
                        <a href="#" onClick={this.handlePrivacyPolicyModalToggle}>
                          {texts[this.props.langKey].titles.labels.TOSandPrivacy_4}
                        </a>
                      </label>
                      {isError.termsOfService.length > 0 && (
                        <span className="invalid-feedback">
                          {isError.termsOfService}
                        </span>
                      )}
                    </div>
                    <SimpleModal
                      showState={this.state.termsOfUseModalShow}
                      handleClose={this.handleTermsOfUseModalToggle}
                      content={termsOfUseTexts[this.props.langKey]}
                      closeButtonText={texts[this.props.langKey].buttons.close}
                    />
                    <SimpleModal
                      showState={this.state.privacyPolicyModalShow}
                      handleClose={this.handlePrivacyPolicyModalToggle}
                      content={privacyPolicyTexts[this.props.langKey]}
                      closeButtonText={texts[this.props.langKey].buttons.close}
                    />
                    <div className="col"></div>
                    {/* Not using Checkbox-component for "marketing authorization" checkbox because issues
                    with formatting label text. */}
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="marketing"
                        name="marketing"
                        checked={this.state.marketing}
                        onChange={this.handleCheckboxTick}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="marketing"
                        title={texts[this.props.langKey].titles.labels.marketing}
                      >
                        {texts[this.props.langKey].titles.labels.marketing}
                      </label>
                    </div>
                  </div>
                </div>
                {/* Checkboxes END */}
              </div>
              {/* Contact person END */}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="row justify-content-end">
                  <Navigation
                    nextHandler={this.nextHandler}
                    langKey={this.props.langKey}
                  />
                </div>
              </div>
            </div>
          </form>
        }
      </>
    );
  }
}

export default LocationForm;
