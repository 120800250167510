import React, { Component } from "react";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import Input from "../components/Input";
import { VALIDATE_EMAIL } from "../general";
import { texts } from "../components/multilanguage";


export default class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langKey: this.props.langKey,

      email: this.props.newUser.contactEmail,
      contactEmail: this.props.newUser.contactEmail,

      companyName: this.props.newUser.companyName,
      companyVatId: this.props.newUser.companyVatId,
      companyAddress: this.props.newUser.companyAddress,
      companyZip: this.props.newUser.companyZip,
      companyCity: this.props.newUser.companyCity,

      locationName: this.props.newUser.locationName,
      locationSpace: this.props.newUser.locationSpace,
      locationCity: this.props.newUser.locationCity,

      contactName: this.props.newUser.contactName,
      contactPhone: this.props.newUser.contactPhone,

      customer: null,
      customerIdToken: null,

      succeeded: false, // needed?
      error: null,
      disabled: true,
      subscribing: false,
      cardElementComplete: null,

      paymentIntent: null,
      setupIntent: null,

      subscriptionId: "",
      cardConfirmed: false,
      clientSecretPaymentIntent: "",
      clientSecretPendingSetupIntent: "",

      selectedProduct: {
        streamFactory: true,
        streamFactoryWithJukebox: false
      },

      isError: {
        email: "",
      },
    };

    this.errorPrompts = {};
  }

  checkErrors = (name, value) => {
    switch (name) {
      case "email":
        this.errorPrompts[name] = VALIDATE_EMAIL(value) ? "" : texts[this.props.langKey].errors.emailInvalid;
        break;
      default:
        break;
    }
    return this.errorPrompts[name];
  };

  formValChange = (e) => {
    e.preventDefault();

    let tempError;
    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      tempError = this.checkErrors(name, value);
    }

    if (tempError === "")
      isError[name] = tempError;

    this.setState({
      [name]: value,
      isError,
    });
  };

  formValBlur = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const isError = { ...this.state.isError };

    if (isError.hasOwnProperty(name)) {
      isError[name] = this.checkErrors(name, value);
    }

    this.setState({
      isError,
    });
  };

  //kuunnellaan cardelementin muutoksia
  //näytetään kaikki virheet käyttäjälle kun se kirjoittaa tietoja
  handleChange = async (event) => {
    this.setState({
      cardElementComplete: event.complete,
      disabled: event.empty,
      error: event.error ? event.error.message : "",
    });
  };

  getChosenProductsPriceIds = () => {
    const priceIds = [this.props.productData.streamFactory.priceId]
    if (this.state.selectedProduct.streamFactoryWithJukebox) {
      priceIds.push(this.props.productData.jukebox.priceId)
    }
    return priceIds
  }

  createStripeCustomer = async () => {
    const locationSpace = this.state.locationSpace;

    const billingEmail = this.state.email;
    const locationName = this.state.locationName;
    const contactName = this.state.contactName;
    const contactEmail = this.props.newUser.contactEmail;

    const companyName = this.state.companyName;
    const contactPhone = this.state.contactPhone;
    const companyVatId = this.state.companyVatId;

    const companyAddress = this.state.companyAddress;
    const companyZip = this.state.companyZip;
    const companyCity = this.state.companyCity;

    const config = {
      method: "post",
      url: "/api/customers",
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        billingEmail,
        contactEmail,
        contactName,
        locationName,
        locationSpace,
        companyName,
        contactPhone,
        companyVatId,
        companyAddress,
        companyCity,
        companyZip,
        country: "FI",
      }
    };

    try {
      const response = await axios(config);
      // console.log("Customer succesfully created:", response.data.customer);
      this.setState({
        customer: response.data.customer,
        customerIdToken: response.data.customerIdToken,
      });
      this.props.setCustomerIdToken(response.data.customerIdToken);
      return true;
    }
    catch (error) {
      // console.log("Creating customer failed:", error);
      this.setState({
        error: texts[this.props.langKey].errors.stripe_subscription
      });
      return false;
    }
  }

  createStripeSubscription = async () => {
    const priceIds = this.getChosenProductsPriceIds();

    try {
      const response = await axios({
        method: "post",
        url: "/api/subscriptions",
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 10000,
        data: {
          customerIdToken: this.state.customerIdToken,
          priceIds: priceIds,
        },
      });

      const { subscriptionId, clientSecretPaymentIntent, clientSecretPendingSetupIntent } = response.data;

      // console.log(`subscriptionId: ${subscriptionId} | clientSecretPaymentIntent: ${clientSecretPaymentIntent} | clientSecretPendingSetupIntent: ${clientSecretPendingSetupIntent}`);
      this.setState({
        subscriptionId,
        clientSecretPaymentIntent,
        clientSecretPendingSetupIntent,
      });
      return true;
    }
    catch (error) {
      // console.log("Failed to create subscription.");
      this.setState({
        error: texts[this.props.langKey].errors.stripe_subscription
      });
      return false;
    }
  }

  confirmCard = async () => {
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      return false;
    }

    const cardElement = elements.getElement(CardElement);

    // Subscription without a trial.
    if (this.state.clientSecretPaymentIntent) {
      let { paymentIntent, error } = await stripe.confirmCardPayment(this.state.clientSecretPaymentIntent, {
        payment_method: {
          card: cardElement
        }
      });

      if (error) {
        // console.log("confirmCardPayment error:", error);
        this.setState({
          error: error.message,
        })
        return false;
      }

      // console.log("paymentIntent:", paymentIntent);
      this.setState({
        paymentIntent,
        cardConfirmed: true,
      });
    }
    // Subscription with a trial.
    else if (this.state.clientSecretPendingSetupIntent) {
      let { setupIntent, error } = await stripe.confirmCardSetup(this.state.clientSecretPendingSetupIntent, {
        payment_method: {
          card: cardElement
        }
      });

      if (error) {
        // console.log("confirmCardSetup error:", error);
        if (error.code === "setup_intent_authentication_failure") {
          this.setState({
            error: texts[this.props.langKey].errors.card_err_3,
          });
        }
        else {
          this.setState({
            error: error.message,
          });
        }
        return false;
      }

      // console.log("setupIntent:", setupIntent);
      this.setState({
        setupIntent,
        cardConfirmed: true,
      });
    }
    return true;
  }

  createSFUser = async () => {
    const newAcco = {
      username: this.props.accountInfo.username,
      password: this.props.accountInfo.password,
      passwordConfirm: this.props.accountInfo.passwordConfirm,
      customerIdToken: this.state.customerIdToken,
    };

    const res = await this.props.addAccount(newAcco);

    if (res === 201) {
      this.setState({
        succeeded: true,
        subscribing: false,
      });

      this.props.proceed();
    } else {
      // Username already exists.
      if (res === 409) {
        this.setState({
          succeeded: false,
          subscribing: false,
          error: texts[this.props.langKey].errors.accountAlreadyExists,
        });
      } else {
        this.setState({
          succeeded: false,
          subscribing: false,
          error: texts[this.props.langKey].errors.creatingStreamFactoryUser,
        });
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ subscribing: true });

    if (!this.state.customer) {
      // console.log("Customer does not exist. Creating customer.");
      if (!await this.createStripeCustomer()) {
        this.setState({ subscribing: false });
        return false;
      }
    }

    if (!this.state.subscriptionId) {
      // console.log("Subscription does not exist. Creating subscription.");
      if (!await this.createStripeSubscription()) {
        this.setState({ subscribing: false });
        return false;
      }
    }

    if (!this.state.cardConfirmed && (this.state.clientSecretPaymentIntent || this.state.clientSecretPendingSetupIntent)) {
      // console.log("Payment intent or setup intent exists. Attempting to confirm.");
      if (!await this.confirmCard()) {
        this.setState({ subscribing: false });
        return false;
      }
    }

    if ((this.state.paymentIntent && this.state.paymentIntent.status === "succeeded")
      || (this.state.setupIntent && this.state.setupIntent.status === "succeeded")) {
      if (!await this.createSFUser()) {
        return false;
      }
    }
  };

  onProductChoice = (e) => {
    this.setState({
      selectedProduct: {
        streamFactory: e.target.id === "streamFactoryRadio",
        streamFactoryWithJukebox: e.target.id === "streamFactoryWithJukeboxRadio"
      }
    });
    this.props.addUserData({ jukebox: e.target.id === "streamFactoryWithJukeboxRadio" ? 1 : 0 });
  }

  componentDidMount() {
    this.props.updateLanguage(this.props.langKey);
  }

  render() {
    const { isError } = this.state;

    const chosenProduct = this.state.selectedProduct.streamFactory
      ? this.props.productData.streamFactory.name
      : this.props.productData.streamFactory.name + " + " + this.props.productData.jukebox.name;
    const priceTotal = this.state.selectedProduct.streamFactory ?
      "" + this.props.productData.streamFactory.price :
      (this.props.productData.streamFactory.price + this.props.productData.jukebox.price);

    return (
      <>
        <h2>{this.props.step} {texts[this.props.langKey].titles.pageTitles.checkoutForm}</h2>
        <form
          id="payment-form"
          className="container"
          onSubmit={this.handleSubmit}
          noValidate
        >

          {/* TODO: Maybe refactor to component */}
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="product-selection-container">
                <input
                  type="radio"
                  id="streamFactoryRadio"
                  name="productChoice"
                  className="custom-control-input"
                  checked={this.state.selectedProduct.streamFactory}
                  onChange={this.onProductChoice}
                />
                <label className="product-selection-label" htmlFor="streamFactoryRadio">
                  <span className={this.state.selectedProduct.streamFactory ? "fa fa-check-circle product-selection-radio-button" : "fa fa-circle-o product-selection-radio-button"}></span>
                  StreamFactory
                  <span>{this.props.productData.streamFactory.price} €/{texts[this.props.langKey].monthAbbr}</span>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="product-selection-container">
                <input
                  type="radio"
                  id="streamFactoryWithJukeboxRadio"
                  name="productChoice"
                  className="custom-control-input"
                  checked={this.state.selectedProduct.streamFactoryWithJukebox}
                  onChange={this.onProductChoice}
                />
                <label className="product-selection-label" htmlFor="streamFactoryWithJukeboxRadio">
                  <span className={this.state.selectedProduct.streamFactoryWithJukebox ? "fa fa-check-circle product-selection-radio-button" : "fa fa-circle-o product-selection-radio-button"}></span>
                  StreamFactory
                  <br />
                  + Jukebox
                  <span>{this.props.productData.streamFactory.price + this.props.productData.jukebox.price} €/{texts[this.props.langKey].monthAbbr}</span>
                </label>
              </div>
            </div>
          </div>
          <p className="small">{texts[this.props.langKey].titles.labels.trial}</p>

          <div className="row mb-1">
            <div className="card">
              <div className="card-body pb-1">
                <p className="card-text">
                  {texts[this.props.langKey].titles.labels.checkout_label}
                </p>
                <div className="row border-bottom">
                  <div className="col col-md-7 p-0">
                    <Input
                      clsName={
                        isError.email.length > 0
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                      onChange={this.formValChange}
                      onBlur={this.formValBlur}
                      name="email"
                      inputType="email"
                      value={this.state.email}
                      placeholder={
                        texts[this.props.langKey].placeholders.contactEmail
                      }
                      err={
                        isError.email.length > 0 && (
                          <span className="invalid-feedback"> {isError.email} </span>
                        )
                      }
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="form-group col-md-7 offset-md-2_5">
                    <CardElement
                      id="card-element"
                      onChange={this.handleChange}
                      options={{ hidePostalCode: true }}
                    />
                  </div>
                </div>
                {/* Näytä mikä tahansa virhe tässä kun koitetaan subata. */}
                <div className="card-error text-danger text-center mb-3" role="alert">
                  {this.state.error}
                </div>

                <div>
                  <div className="text-center h5 mb-0">{chosenProduct}</div>
                  <div className="text-center h3 mb-0 font-weight-bold">{priceTotal + " €/" + texts[this.props.langKey].monthAbbr}</div>
                  <div className="text-center small">{(Math.round(priceTotal * 100 * 1.24) / 100).toFixed(2).replace(".", texts[this.props.langKey].decimalPoint)}{texts[this.props.langKey].subscription.includingVAT}</div>
                </div>

                <div className="text-center mt-2">
                  <button
                    disabled={
                      this.state.subscribing ||
                      this.state.disabled ||
                      this.state.succeeded ||
                      !this.state.cardElementComplete ||
                      this.state.email.length === 0 ||
                      this.state.companyVatId.length === 0 ||
                      isError.email.length > 0
                    }
                    id="submit"
                    className="btn btn-custom btn-outline-primary btn-subscribe mt-0 mb-3 mx-0"
                  >
                    <span id="button-text">
                      {this.state.subscribing
                        ? <div className="spinner" id="spinner"></div>
                        : texts[this.props.langKey].navigation.order}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};
